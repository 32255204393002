// Called by AddCommunityOrderButton.jsx this function will add a user to both
// the DistributionLocations Collection and to the individual user's order.
import YYYYMMDDConverter from "../../../pages/BasketPage/Functions/YYYYMMDDConverter.js";
import firebase from "../../../components/Firebase.js";

export default function CreateCommunityMemberOrder(userInfo, distributionDate) {
  const database = firebase.firestore();
  const batch = database.batch();

  // Sets the pickup location to the user's pickup location
  let pickupLocation = userInfo.pickupLocation;
  // Sets the communityPickup to an empty array.
  let communityPickup = [];
  // Checks if the user has selected a community location.
  if (pickupLocation.pickupLocation !== undefined) {
    // If it has then update the community Pickup
    communityPickup = pickupLocation;
    // If it has then update the distibution location.
    pickupLocation = pickupLocation.pickupLocation;
  }

  // Write to the Order collection in the database
  const now = YYYYMMDDConverter();
  const ordersDocRef = database
    .collection("Users")
    .doc(userInfo.userId)
    .collection("Orders")
    .doc(now);
  // Set the location of the DistributionLocations in the database
  const distributionLocationOrdersDocRef = database
    .collection("DistributionLocations")
    .doc(pickupLocation.name)
    .collection("CommunityOrders")
    .doc(distributionDate);

  batch.set(ordersDocRef, {
    orderDate: now,
    foodList: [],
    farmerList: [],
    contactMethods: {},
    pickupLocation,
    donationSubsidy: parseFloat(0),
    customerContribution: "donation",
    communityPickup,
    cashPortion: "0.00",
    creditPortion: "0.00",
    phoneNumber: userInfo.phoneNumber,
  });
  batch.set(
    distributionLocationOrdersDocRef,
    {
      [userInfo.firstName +
      "-" +
      userInfo.lastName +
      "-" +
      userInfo.userId +
      "-" +
      now]: {
        orderDate: now,
        foodList: [],
        farmerList: [],
        donationSubsidy: parseFloat(0),
        customerContribution: "donation",
        communityPickup,
        cashPortion: "0.00",
        creditPortion: "0.00",
        phoneNumber: userInfo.phoneNumber,
      },
    },
    { merge: true },
  );

  // commit the changes to the database.
  batch.commit();
}
