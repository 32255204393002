// This is called to update the foodList of the distribution location and updates
// the community orders and the imported orders that also need to be updated.
import UpdatePriceAndCommunity from "./SyncFoodDistributionCommunityOrders/UpdatePriceAndCommunity.js";

// props comtains:
// ordersToChange : Array with firstName-lastName-userId-orderDate of all communityOrders to be changed
// importedOrdersToChange : Array with firstName-lastName-userId-orderDate of all importedOrder to be changed
export default function UpdateFoodItemsDistributionLocation(
  props,
  ordersToChange,
  importedOrdersToChange,
  state,
  updatePaymentHistory,
  communityOrderChunks,
  setCommunityOrderChunks,
) {
  const limit = state.limit;
  const item = state.item;
  const distributionQuantity = state.distributionQuantity;
  const description = state.description;
  const individualQuantity = state.individualQuantity;
  const individualDescription = state.individualDescription;
  let suggestedPrice = state.suggestedPrice;
  const price = state.price;
  const category = state.category;
  let displayImage = state.displayImage;
  const farmName = state.farmName;
  const packageFee = state.packageFee;
  const tagList = state.tagList;

  // The distribution location's userInfo
  const userInfo = props.userInfo;
  // communityOrders: {}, contactMethods: {}, farmerList: [{…}], foodList: [{…}], importedDistributionDate: null, importedDistributionLocation: null, importedOrder: {}, orderDate: "202012101607633156316", owner: "CNjgN4YCGkNlpaBOXukfHz2neLi2", selectedDate:"12-20-2020"
  const order = props.order;
  // a function to update OrderLog
  const updateOrderLog = props.updateOrderLog;
  // the index of the current order in the orderLog array
  const orderIndex = props.orderIndex;
  // This is the foodItem that was from before.
  const oldFoodItem = props.foodItem;

  // Find the index of the foodItem in the overall order for the distribution location
  let foodListIndex = -1;
  // Create a temporary foodList to modify
  let foodListTemp = [];

  // Set the field to update.
  let fieldToUpdate = "";

  // Since suggestedPrice was added later on we have to make sure
  // that is defined otherwise it will trigger a bug.
  let suggestedPriceProps = oldFoodItem.suggestedPrice;
  if (oldFoodItem.suggestedPrice === undefined) {
    suggestedPriceProps = false;
  }

  // This is the old foodItem that we are updating.
  const foodItemUpdate = { ...oldFoodItem };
  foodItemUpdate.suggestedPrice = suggestedPriceProps;

  // This is the new and updated food Item.
  const updatedFoodItem = { ...state };

  // If the limit exists then we're just updating the pantryItems
  if (limit) {
    foodListIndex = order.pantryFoodItems.findIndex(
      (element) =>
        element.item === foodItemUpdate.item &&
        element.description === foodItemUpdate.description &&
        element.farmName === foodItemUpdate.farmName &&
        !!element.limit === !!foodItemUpdate.limit,
    );
    foodListTemp = [...order.pantryFoodItems];
    fieldToUpdate = "pantryFoodItems";
  }
  // Otherwise we're updating the foodList.
  else {
    foodListIndex = order.foodList.findIndex(
      (element) =>
        element.item === foodItemUpdate.item &&
        element.description === foodItemUpdate.description &&
        element.farmName === foodItemUpdate.farmName &&
        !!element.limit === !!foodItemUpdate.limit,
    );
    foodListTemp = [...order.foodList];
    fieldToUpdate = "foodList";
  }

  // Save the old price so that we can record the price change on the user's order.
  const originalPriceIndividual =
    (foodItemUpdate.price / foodItemUpdate.distributionQuantity) *
    foodItemUpdate.individualQuantity;

  // Save the new price so that we can record the price change on the user's order.
  const newPriceIndividual =
    (price / distributionQuantity) * individualQuantity;

  // Since the user doesn't have to insert an image we just give an empty string
  // value so that it isn't undefined which can't be saved in the database.
  if (displayImage === undefined) {
    displayImage = "";
  }

  // Since the user may not have inserted a value we set it to false
  // so that it isn't undefined which can't be saved in the database.
  if (suggestedPrice === undefined) {
    suggestedPrice = false;
  }

  // Updates the values of all the foodList properties.  If they didn't change then
  // they'll just keep their same values.
  foodListTemp[foodListIndex] = { ...state };
  // If the limit exists then set it.
  if (limit) {
    foodListTemp[foodListIndex].limit = limit;
  }

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...props.orderLog];

  // If there are changes to the foodItem that requires a change to the community
  // orders then update that.
  let updateCommunityOrders = false;

  // If the price of the foodItem changed then set this to true so we know that
  // payment tracking also needs to be updated.
  let priceUpdate = false;
  // If any of the following stats are changed then the price will be effected.
  if (
    !(
      foodItemUpdate.distributionQuantity === distributionQuantity &&
      foodItemUpdate.individualQuantity === individualQuantity &&
      foodItemUpdate.price === price
    )
  ) {
    priceUpdate = true;
  }

  // If any of the following items have been changed then the community orders will
  // need to be updated as well.
  if (
    !(
      foodItemUpdate.item === item &&
      foodItemUpdate.distributionQuantity === distributionQuantity &&
      foodItemUpdate.description === description &&
      foodItemUpdate.individualQuantity === individualQuantity &&
      foodItemUpdate.individualDescription === individualDescription &&
      foodItemUpdate.suggestedPrice === suggestedPrice &&
      foodItemUpdate.price === price &&
      foodItemUpdate.category === category &&
      foodItemUpdate.displayImage === displayImage &&
      foodItemUpdate.farmName === farmName &&
      foodItemUpdate.packageFee === packageFee &&
      foodItemUpdate.tagList === tagList
    )
  ) {
    updateCommunityOrders = true;
  }

  // This will update the price and also check if the community orders need to
  // be updated and will update them.
  UpdatePriceAndCommunity(
    userInfo,
    order,
    ordersToChange,
    importedOrdersToChange,
    updateOrderLog,
    updatePaymentHistory,
    orderIndex,
    foodListTemp,
    fieldToUpdate,
    foodItemUpdate,
    orderLogTemp,
    updateCommunityOrders,
    "updateFoodItem",
    updatedFoodItem,
    priceUpdate,
    originalPriceIndividual,
    newPriceIndividual,
    null,
    null,
    null,
    communityOrderChunks,
    setCommunityOrderChunks,
  );
}
