// Once the user orders are dropped off then we need to update the user's accounts
// and the database paymentHistory, distributionLocation account, individual account.
import firebase from "../../../../../components/Firebase.js";
import UpdatePaymentHistoryDeliveryFee from "./UpdatePaymentHistoryDeliveryFee.js";
import CheckSubsidyAmount from "./CheckSubsidyAmount.js";
import UpdateCommunityOrdersDatabase from "../../../../../pages/BasketPage/Functions/OrderUpdates/UpdateCommunityOrdersDatabase.js";

export default function FinalizeDeliveryFees(
  communityHubUsers,
  checkList,
  order,
  updatePaymentHistory,
  baseDeliveryFee,
  communityOrderChunks,
  setCommunityOrderChunks,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // The list of the communityOrders this might have multiple orders per person
  const communityOrdersTemp = { ...order.communityOrders };
  // The order list to update that will be one entry per person so that the we know
  // which user is needing updated.  It will have the locationName as the upper key
  // as each location delivery fee.
  const orderListToUpdate = [];
  // This array holds all the users that have had the delivery fee added to their order.
  // Some users may have placed multiple orders so we only want to charge them the delivery
  // fee once so that way we will know which orders we've added to add will skip additional orders.
  const deliveryFeeAdd = [];
  // Payment history to update with person as the key and the delivery fee as the
  // value.
  const paymentHistoryToUpdate = {};

  // Cycle through the checkList and add the user to a list so that we have the
  // information needed per user and a list with only one entry per person.
  Object.keys(checkList).forEach((user) => {
    // Check if the user has been checked and needs to be updated.
    if (checkList[user]) {
      // Cycle through the community orders.
      Object.keys(communityOrdersTemp).forEach((userOrder) => {
        // Create the community location name as we will have different delivery fees.
        const communityLocationName =
          communityOrdersTemp[userOrder].communityPickup.locationName;
        // Split the user so we can look at their firstName, lastName and userId
        const userSplit = userOrder.split("-");
        // Remove the date as the checklist doesn't have the date included.
        if ([userSplit[0], userSplit[1], userSplit[2]].join("-") === user) {
          // Add the the user to the update list under the community name
          if (orderListToUpdate[communityLocationName]) {
            orderListToUpdate[communityLocationName].push(userOrder);
          } else {
            orderListToUpdate[communityLocationName] = [userOrder];
          }
        }
      });
    }
  });

  // Cycle through each location.
  Object.keys(orderListToUpdate).forEach((locationName) => {
    // Calculate the number of users at the location.
    const numberOfUsers = Object.keys(communityHubUsers[locationName]).length;
    // The delivery fee is the base amount divided all the users.
    const deliveryFee = baseDeliveryFee / numberOfUsers;
    // If the user has already been sent a drop off message then we don't want to add
    // the delivery fee again so we just check if was added.  If it was then we will subtract
    // the already added amount so that if the user gets a refund that will be updated to their
    // account.
    let deliveryFeeAlreadyAdded = 0;
    // Cycle through the users at this location and add the delivery fee to the database.
    // It is sorted to make sure the oldest order is the one with the delivery fee.
    orderListToUpdate[locationName].sort().forEach((orderToUpdate) => {
      const orderToUpdateSplit = orderToUpdate.split("-");
      // If the user has placed multiple orders and we've already added the delivery fee
      // then we don't want to add it again.
      if (
        !deliveryFeeAdd.includes(
          [
            orderToUpdateSplit[0],
            orderToUpdateSplit[1],
            orderToUpdateSplit[2],
          ].join("-"),
        )
      ) {
        // Add the user to the delivery fee list so we don't add it again.
        deliveryFeeAdd.push(
          [
            orderToUpdateSplit[0],
            orderToUpdateSplit[1],
            orderToUpdateSplit[2],
          ].join("-"),
        );
        // Update the individual user's order.
        const userDocRef = database
          .collection("Users")
          .doc(orderToUpdateSplit[2])
          .collection("Orders")
          .doc(orderToUpdateSplit[3]);
        // Set the subsidy/donation amount that the user has.
        const donationSubsidyOriginal =
          communityOrdersTemp[orderToUpdate].donationSubsidy;
        // This is the subsidy/donation amount in case it changes because the subsidy is greater
        // than the total owed.
        let donationSubsidyNew =
          communityOrdersTemp[orderToUpdate].donationSubsidy;

        // If the user is using a subsidy make sure that their subsidy isn't more then
        // the order as the delivery fee may have gone down since they last ordered.
        if (
          communityOrdersTemp[orderToUpdate].customerContribution === "subsidy"
        ) {
          // The updated donation subsidy.  It may be the same or updated.
          donationSubsidyNew = CheckSubsidyAmount(
            communityOrdersTemp[orderToUpdate],
            deliveryFee,
          );
        }
        // Update the individual user's order document.
        batch.update(userDocRef, {
          deliveryFee,
          donationSubsidy: donationSubsidyNew,
        });

        // Find the difference between the old and new subsidy amounts if there is one.
        // If there isn't then this value will just be 0.
        const donationSubsidyDifference =
          donationSubsidyOriginal - donationSubsidyNew;

        // If there already is a delivery fee we want to take that into account when
        // updating the payment history.  That means we might have already sent the email
        // to the user and so we don't want to add the delivery fee again.
        if (communityOrdersTemp[orderToUpdate].deliveryFee !== undefined) {
          deliveryFeeAlreadyAdded = parseFloat(
            communityOrdersTemp[orderToUpdate].deliveryFee,
          );
        }

        // Payment history is the list of users to update with the fees.  We add the delivery fee
        // with the subsidy amount change and the subtract the delivery fee we've already added
        // from a previous order email.  This may be a negative number if the user had a delivery fee
        // that was greater than the current one.
        paymentHistoryToUpdate[
          [
            orderToUpdateSplit[0],
            orderToUpdateSplit[1],
            orderToUpdateSplit[2],
          ].join("-")
        ] = deliveryFee + donationSubsidyDifference - deliveryFeeAlreadyAdded;

        // Add the delivery fee to the community orders so that they can be updated
        // in the database at the distributionLocation.
        communityOrdersTemp[orderToUpdate].deliveryFee = deliveryFee;
        // This may or may not be the same as the old one but still can update it.
        communityOrdersTemp[orderToUpdate].donationSubsidy = donationSubsidyNew;
      }
    });
  });

  // Update the database at the distribution location orders log.  Complete Overwrite means that communityOrders will be redone and resaved to the
  // database.
  UpdateCommunityOrdersDatabase(
    "CompleteOverwrite",
    batch,
    communityOrderChunks,
    setCommunityOrderChunks,
    communityOrdersTemp,
    order.orderDate,
  );

  // Update the paymentHistory to include these amounts for individuals.
  UpdatePaymentHistoryDeliveryFee(
    paymentHistoryToUpdate,
    order.orderDate,
    updatePaymentHistory,
  );

  batch.commit();
}
