// This is used to display the row on the myaccount page of a foodItem that was purchased
// from a vendor.
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FoodItemPriceChangeDialog from "../../../components/dialogs/FoodItemPriceChangeDialog.jsx";
import EditDistributionLocationItemForm from "./EditDistributionLocationItemForm.jsx";
import { getIconByKey } from "../../../images/Icons"; // Import the function to get icons

export default function MyAccountFoodRow({
  idx,
  food,
  editing,
  multipleSelection,
  userInfo,
  order,
  orderLog,
  updateOrderLog,
  orderIndex,
  handleSelectionChange,
  publicView,
}) {
  // The individual description of the food item for the user to know how
  // much they are purchasing.
  let individualDescription = food.individualDescription;
  // The description of the food item for a distribution location user to know how
  // much they are purchasing.
  let description = food.description;
  // Set the price of the individal item
  let individualPrice = parseFloat(
    food.price / (food.distributionQuantity / food.individualQuantity),
  );
  // Set the price of the distribution location item
  let price = parseFloat(food.price);

  // if suggestedPrice is true then we want to set the price to 0
  if (food.suggestedPrice) {
    // Add the suggested donation to the description.
    individualDescription = individualDescription.concat(
      " - Suggested Donation of $",
      individualPrice,
    );
    description = description.concat(" - Suggested Donation of $", price);
    // Then reset the prices to 0.
    individualPrice = 0.0;
    price = 0.0;
  }

  return (
    <div key={idx} className="Editing-Remove-Food-Item">
      <div key={food + idx} className="Food-Item">
        <div className="Food-Item-Desc-Quantity">
          <div className="Food-Item-Tags-And-Name">
            {userInfo.userType === "individual" && !publicView ? (
              <h5 className="Food-Item-Child Food-Item-Header">
                {food.item}, {individualDescription}
              </h5>
            ) : (
              <h5 className="Food-Item-Child Food-Item-Header">
                {food.item}, {description}
              </h5>
            )}
            {/* Check if food.tagList exists and has items */}
            {food.tagList && food.tagList.length > 0 && (
              <div style={{ display: "inline-flex", marginLeft: "8px" }}>
                {food.tagList.map((tag) => (
                  <span key={tag.key} style={{ marginRight: "4px" }}>
                    {getIconByKey(tag.key)} {/* Render the icon for each tag */}
                  </span>
                ))}
              </div>
            )}
          </div>
          <h5 className="Food-Item-Child Food-Item-Header">x{food.quantity}</h5>
        </div>
        <div className="Order-Bottom-Row">
          <p className="Food-Item-Child">{food.farmName}</p>
          {food.checked && <p className="Recurring-Stamp"> Recurring </p>}
          {userInfo.userType !== "restaurant" && (
            <span>
              {userInfo.userType === "individual" && !publicView ? (
                <span>
                  {/* This is so that previous orders are still able to be read
                    that occured before the changes to the database */}
                  {food.distributionQuantity === undefined ? (
                    <p className="Food-Item-Child">
                      {" "}
                      ${parseFloat(price).toFixed(2)}{" "}
                    </p>
                  ) : (
                    <>
                      {food.packageFee > 0 ? (
                        <>
                          <p
                            style={{ textAlign: "right" }}
                            className="Food-Item-Child"
                          >
                            {" "}
                            $
                            {parseFloat(
                              food.packageFee * food.quantity +
                                price /
                                  (food.distributionQuantity /
                                    food.individualQuantity),
                            ).toFixed(2)}
                            *{" "}
                          </p>
                          <p
                            style={{ margin: "2px" }}
                            className="Item-Package-Fee"
                          >
                            *includes package fee $
                            {parseFloat(food.packageFee).toFixed(2)}/per item
                          </p>
                        </>
                      ) : (
                        <p className="Food-Item-Child">
                          {" "}
                          ${individualPrice.toFixed(2)}{" "}
                        </p>
                      )}
                    </>
                  )}
                </span>
              ) : (
                <span className="Editing-Price-Change">
                  {editing && !multipleSelection && (
                    <FoodItemPriceChangeDialog
                      userInfo={userInfo}
                      order={order}
                      foodItemUpdate={food}
                      orderLog={orderLog}
                      updateOrderLog={updateOrderLog}
                      orderIndex={orderIndex}
                    />
                  )}
                  <p className="Food-Item-Child">
                    {" "}
                    ${parseFloat(price).toFixed(2)}{" "}
                  </p>
                </span>
              )}
            </span>
          )}
        </div>
      </div>
      {editing && multipleSelection && (
        <Checkbox
          name="foodItem"
          onChange={(e) => handleSelectionChange(e, idx, false)}
        />
      )}
      {editing && !multipleSelection && (
        <EditDistributionLocationItemForm
          foodItem={food}
          order={order}
          foodItemUpdate={food}
          orderLog={orderLog}
          updateOrderLog={updateOrderLog}
          orderIndex={orderIndex}
          userInfo={userInfo}
          index={idx}
        />
      )}
    </div>
  );
}
