// This is used to show a list of farms and food Items that are already
// in the system that can autofill and be added to a distribution order.
// It is called to make it easier to add items to an order.
import React, { useState, useEffect, useContext } from "react";
import { SetVariablesContext } from "./../../AddItemToOrderDialog.jsx";
import FoodItemSelect from "../../../userInputs/FoodItemSelect.jsx";
import FarmCheckboxes from "../../../userInputs/FarmCheckboxes.jsx";
import { SetPantryVariablesContext } from "./../../../../pages/MyAccountPage/Dialogs/AddItemToPantryOrderDialog.jsx";
import { SetAddToPantryVariablesContext } from "./../../../Pantry/Dialogs/AddItemToPantryDialog.jsx";

export default function SelectFoodItemToAdd({
  setItem,
  setDistributionQuantity,
  setDescription,
  setIndividualQuantity,
  setIndividualDescription,
  setSuggestedPrice,
  setPrice,
  setCategory,
  setDisplayImage,
  setQuantity,
  setLimit,
  setFarmName,
  setPackageFee,
  setTagList,
  tagList,
}) {
  // The index of the selected FoodItem that the user is adding.  The index
  // is of the displayedFoodItems.
  const [indexOfSelectedItem, setIndexOfSelectedItem] = useState("");

  // Extract `pantry` from SetPantryVariablesContext
  let contextValues = useContext(SetVariablesContext);
  if (useContext(SetPantryVariablesContext) != null) {
    // Conditionally use one of the contexts based on the value of `pantry`
    contextValues = useContext(SetPantryVariablesContext);
  } else if (useContext(SetAddToPantryVariablesContext)) {
    contextValues = useContext(SetAddToPantryVariablesContext);
  }

  // Destructure the necessary values from `contextValues`
  const {
    displayedFoodItemsToSelect,
    farmersAvailable,
    foodItemsToSelect,
    setDisplayedFoodItemsToSelect,
    open,
  } = contextValues;

  useEffect(() => {
    if (open) {
      setIndexOfSelectedItem("");
      // Set the displayed foodItems to nothing so that the user
      // has to select which farmers to show otherwise it's just too
      // many items to show the user at once.
      setDisplayedFoodItemsToSelect([]);
    } else {
      setIndexOfSelectedItem("");
      setDisplayedFoodItemsToSelect([]);
    }
  }, [open]);

  return (
    <div>
      <FoodItemSelect
        displayedFoodItemsToSelect={displayedFoodItemsToSelect}
        setDistributionQuantity={setDistributionQuantity}
        setDescription={setDescription}
        setIndividualQuantity={setIndividualQuantity}
        setIndividualDescription={setIndividualDescription}
        setSuggestedPrice={setSuggestedPrice}
        setPrice={setPrice}
        setCategory={setCategory}
        setDisplayImage={setDisplayImage}
        setQuantity={setQuantity}
        setFarmName={setFarmName}
        setItem={setItem}
        setLimit={setLimit}
        setPackageFee={setPackageFee}
        setTagList={setTagList}
        tagList={tagList}
        indexOfSelectedItem={indexOfSelectedItem}
        setIndexOfSelectedItem={setIndexOfSelectedItem}
      />
      <FarmCheckboxes
        farmersAvailable={farmersAvailable}
        foodItemsToSelect={foodItemsToSelect}
        displayedFoodItemsToSelect={displayedFoodItemsToSelect}
        setDisplayedFoodItemsToSelect={setDisplayedFoodItemsToSelect}
        setIndexOfSelectedItem={setIndexOfSelectedItem}
      />
    </div>
  );
}
