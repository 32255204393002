import { Sema } from "async-sema";

// The semaphore is needed if batchEmails is not awaited and "batchEmails" called multiple times.
// Set to 2, but maybe needs to be 1.
const semaphore = new Sema(2);

const minuteInMs = 60000;

/**
 * batchedParamsForEmailFunc: Items to use as params for the singularEmailFunc.
 * singularEmailFunc: Async function that calls FireBase email cloud functions. Items from "itemsForEmailFunc" must be valid parameters for this function.
 * delayBetweenItems: Delay in milliseconds between batch email items.
 * emailsPerMinute: If defined, only lets batchEmails func send X emails per minute.
 */
export default async function batchEmails({
  batchedParamsForEmailFunc = [],
  singularEmailFunc,
  delayBetweenItems = 500,
  emailsPerMinute = 28,
}) {
  let counter = 0;
  for (const item of batchedParamsForEmailFunc) {
    await semaphore.acquire();
    await singularEmailFunc(item);
    await new Promise((resolve) => setTimeout(resolve, delayBetweenItems));

    counter++;
    // Not optimized to work with delay in batches.
    if (counter > emailsPerMinute) {
      counter = counter - emailsPerMinute;
      await new Promise((resolve) => setTimeout(resolve, minuteInMs));
    }

    semaphore.release();
  }
}
