// This dialog appears to all a distribution location user to modify their orders.
import React, { useContext } from "react";
import firebase from "../../../components/Firebase.js";
import CommunityOrdersAffected from "../../../functions/CommunityOrdersAffected.js";
import UpdateFoodItemsDistributionLocation from "../Functions/UpdateFoodItemsDistributionLocation.js";
import {
  ChangeOrderLogContext,
  CommunityOrderChunksContext,
} from "../../MyAccount.js";
import AddFoodItem from "../../../components/ModifyOrders/AddFoodItem.js";
import AddPantryFoodItem from "../../../components/ModifyOrders/AddPantryFoodItem.js";
import RemoveFoodItem from "../../../components/ModifyOrders/RemoveFoodItem.js";
import "date-fns";
import FoodItemInformationDialog from "../../../components/dialogs/Components/FoodItemInformationDialog.jsx";

// This is the dialog box that opens when a user clicks the edit button
export default function EditDistributionLocationItemForm(props) {
  // This contains the orderLog and updateOrderLog so that when the user updates
  // the order it displays correctly on the myAccount Page
  // eslint-disable-next-line no-unused-vars
  const { orderLog, updateOrderLog, updatePaymentHistory } = useContext(
    ChangeOrderLogContext,
  );
  // communityOrderChunks are in {orderdate: [communityOrders, overFlowCommunityOrders1, etc.]}
  const { communityOrderChunks = [], setCommunityOrderChunks = () => {} } =
    useContext(CommunityOrderChunksContext) || {};

  // This is when the user saves to the database.
  const handleSave = (state, handleClose) => {
    const limit = state.limit;
    const selectedDate = state.selectedDate;
    const oldFoodItem = props.foodItem;
    // If the limit existence changed then we know that it has been moved from
    // foodItems to the pantryFoodItems.
    if (!!oldFoodItem.limit !== !!limit) {
      // Create the basic foodItem that we know both pantry and new items will include.
      const foodItemToAdd = { ...state };
      // If it was originally a pantry foodItem add it to the foodList.
      if (oldFoodItem.limit) {
        // add the field that was missing.
        foodItemToAdd.selectedDate = selectedDate;
        // Add to foodItems
        AddFoodItem(
          foodItemToAdd,
          props.order,
          props.userInfo,
          props.orderLog,
          props.updateOrderLog,
          props.orderIndex,
        );
        // Remove from pantry
        RemoveFoodItem(
          props.userInfo,
          props.order,
          props.orderLog,
          props.updateOrderLog,
          props.orderIndex,
          props.index,
          props.order.pantryFoodItems[props.index],
          [],
          [],
          false,
          null,
          1,
          communityOrderChunks,
          setCommunityOrderChunks,
        );
      }
      // otherwise it was originally a foodList Item add it to the pantry.
      else {
        // add the field that was missing.
        foodItemToAdd.limit = limit;
        foodItemToAdd.pantryLocation = props.userInfo.organisationName;
        foodItemToAdd.userId = firebase.auth().currentUser.uid;
        // This is hardcoded to match deprecated code
        foodItemToAdd.userType = "admin";

        // Add to pantry
        AddPantryFoodItem(
          foodItemToAdd,
          props.order,
          props.orderLog,
          props.updateOrderLog,
          props.orderIndex,
        );
        // Remove from foodItems
        RemoveFoodItem(
          props.userInfo,
          props.order,
          props.orderLog,
          props.updateOrderLog,
          props.orderIndex,
          props.index,
          props.order.foodList[props.index],
          [],
          [],
          false,
          null,
          0,
          communityOrderChunks,
          setCommunityOrderChunks,
        );
      }
    } else {
      // This sends in the order and the food item that needs to be updated.  It returns
      // an array with the FirstName-LastName-userid-orderDate of all the orders that
      // have this item that is being changed.
      const [ordersToChange, importedOrdersToChange] = CommunityOrdersAffected(
        props.order,
        props.foodItemUpdate,
      );
      // This sends in the array of orders needing to be updated and updated the database.
      UpdateFoodItemsDistributionLocation(
        { ...props },
        ordersToChange,
        importedOrdersToChange,
        { ...state },
        updatePaymentHistory,
        communityOrderChunks,
        setCommunityOrderChunks,
      );
    }

    handleClose();
  };

  return (
    <div>
      <FoodItemInformationDialog
        buttonStyle={0}
        callSaveFunction={handleSave}
        {...props}
      />
    </div>
  );
}
