// This dialog appears when the admin user is adding a volunteer to the volunteer
// schedule this will appear next to their name and will only allow the volunteer
// to be added if they haven't been already.
// eslint-disable-next-line no-unused-vars
import React from "react";
import Button from "@material-ui/core/Button";
import AddVolunteerToShift from "../../Functions/VolunteerSchedule/AddVolunteerToShift.js";

export default function AddVolunteerButton({
  volunteer,
  selectedPosition,
  volunteerSchedule,
  setVolunteerSchedule,
  indexOfQuarter,
  date,
  shift,
  newVolunteer,
  volunteerList,
  setVolunteerList,
}) {
  // Check to see if this volunteer has already been added to the list and if it has
  // find the index of the volunteer in the list.
  const index = volunteerSchedule[indexOfQuarter][date][selectedPosition][
    shift
  ].volunteers.findIndex((currentVolunteer) => {
    return currentVolunteer.userId === volunteer.userId;
  });

  // If the volunteer has already been added to the list.
  let volunteerAlreadyAdded = false;

  // If the index exists then we know they've already been added and don't want them
  // to be able to be added again.
  if (index !== -1) {
    volunteerAlreadyAdded = true;
  }

  return (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      {" "}
      {/* Flex container */}
      {volunteerAlreadyAdded ? (
        <Button disabled style={{ fontWeight: "bold" }}>
          ADDED
        </Button>
      ) : (
        <Button
          onClick={() =>
            AddVolunteerToShift(
              volunteer,
              selectedPosition,
              volunteerSchedule,
              setVolunteerSchedule,
              indexOfQuarter,
              date,
              shift,
              newVolunteer,
              volunteerList,
              setVolunteerList,
            )
          }
          style={{ color: "green", fontWeight: "bold" }} // Change font color and make it bold
        >
          ADD
        </Button>
      )}
    </div>
  );
}
