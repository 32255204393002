// This function will reorder the list of foodItems and pantryItems depending on
// what the user has requested. If the user wants alphabetically the items will be sorted
// alphatically by their name.  For the By Farm the items will sorted alphatically by the farm's
// name.  For the By order it will be sorted in the order that user ordered the items in.  If the user
// wants the program to sort the items by farmName and item name alphatically they'll have to run the sort
// function twice.  For the By Category it will sort the items by category and put the Dairy and Eggs and then
// the Frozen Foods first.

export function ReorderFoodItems(
  foodList,
  setFoodList,
  originalOrder,
  pantryFoodItems,
  setPantryFoodItems,
  originalPantryFoodItems,
  alphabetically,
  byFarm,
  byOrdered,
  byCategory,
) {
  // Helper function to handle sorting by a given key.
  const sortByKey = (arr, key) => {
    return arr.sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
  };

  // Helper function to handle sorting by tagList.
  const sortByTagList = (arr) => {
    return arr.sort((a, b) => {
      const aHasTagList = Array.isArray(a.tagList) && a.tagList.length > 0;
      const bHasTagList = Array.isArray(b.tagList) && b.tagList.length > 0;

      if (!aHasTagList && bHasTagList) return 1;
      if (aHasTagList && !bHasTagList) return -1;
      if (!aHasTagList && !bHasTagList) return 0;

      const aFirstTag = a.tagList[0].label.toLowerCase();
      const bFirstTag = b.tagList[0].label.toLowerCase();

      if (aFirstTag < bFirstTag) return -1;
      if (aFirstTag > bFirstTag) return 1;
      return 0;
    });
  };

  // Create a temporary food list to modify.
  let foodListTemp = [...foodList];
  // Create a temporary pantry food list to modify.
  let pantryFoodItemsTemp = [...pantryFoodItems];

  // If the user chose to sort alphatically sort by the item name.
  if (alphabetically) {
    foodListTemp = sortByKey(foodListTemp, "item");
    pantryFoodItemsTemp = sortByKey(pantryFoodItemsTemp, "item");
  }

  // Sort alphabetically by farmName.
  if (byFarm) {
    foodListTemp = sortByKey(foodListTemp, "farmName");
    pantryFoodItemsTemp = sortByKey(pantryFoodItemsTemp, "farmName");
  }

  // Sort by the original order.
  if (byOrdered) {
    foodListTemp = [...originalOrder];
    if (originalPantryFoodItems) {
      pantryFoodItemsTemp = originalPantryFoodItems;
    }
  }

  // Sort by tagList.
  if (byCategory) {
    foodListTemp = sortByTagList(foodListTemp);
    pantryFoodItemsTemp = sortByTagList(pantryFoodItemsTemp);
  }

  // Set the foodList.
  setFoodList(foodListTemp);
  // If pantry items exist then set them as well.
  if (originalPantryFoodItems) {
    setPantryFoodItems(pantryFoodItemsTemp);
  }
}
