import PropTypes from "prop-types"; // Import PropTypes for type checking
import firebase from "../../../../components/Firebase.js";
import AggregateCustomerList from "../../../../functions/AggregateOrder.js";
import VolunteerFeeAdjustment from "../../../../components/ModifyOrders/VolunteerFeeAdjustment.js";

// This is called by a distribution location's basket in case there are
// items that need to be distributed amongst users that have a limited
// amount allowed. This will then update the basket page.
function ExtraQuantitiesUpdate(
  communityOrders,
  setCommunityFoodItems,
  setCommunityFarmerInfo,
  setCommunityDonations,
  setCommunitySubsidies,
  setPantryFoodItems,
  setCommunityOrders,
  setExtraQuantitiesDict,
  userInfo,
  basketFoodItems,
) {
  // Used to keep track of users that ordered extra items that may
  // not be available.
  const extraQuantitiesDict = {};

  // The communityOrders that we'll update with their new values.
  const communityOrdersTemp = { ...communityOrders };

  // Take the communityOrders that was taken from the database and get each of
  // their keys.
  Object.keys(communityOrders).forEach((customer) => {
    // Create a customer Order equal to this will be the new community orders
    // with all the orders properly aggregated.
    const customerOrder = { ...communityOrders[customer] };

    customerOrder.foodList.forEach((food, foodIndex) => {
      // If there is a guaranteedMaxQuantity then we will only add
      // their guaranteedMaxQuantity first and then if there is more
      // available we will add the extra.
      if (
        typeof food.guaranteedMaxQuantity === "number" &&
        food.guaranteedMaxQuantity > 0 &&
        (food.limit === null || food.limit === "")
      ) {
        // Check to see if the user ordered more than the guaranteedMaxQuantity
        if (food.guaranteedMaxQuantity < food.quantity) {
          // The extra quantity is set to whatever they ordered that isn't
          // guaranteed.
          const extraQuantity = food.quantity - food.guaranteedMaxQuantity;
          // Check to see if this food item has already been added to the extra dict
          if (
            [food.item, food.description, food.farmName].join("-") in
            extraQuantitiesDict
          ) {
            // Check to see if this customer already ordered this item.  This can happen
            // if they've ordered it in two separate orders.
            if (
              customer in
              extraQuantitiesDict[
                [food.item, food.description, food.farmName].join("-")
              ]
            ) {
              extraQuantitiesDict[
                [food.item, food.description, food.farmName].join("-")
              ][customer] += extraQuantity;
            }
            // The customer hasn't been added yet.
            else {
              extraQuantitiesDict[
                [food.item, food.description, food.farmName].join("-")
              ][customer] = extraQuantity;
            }
          }
          // The food item has to be added and the first customer.
          else {
            extraQuantitiesDict[
              [food.item, food.description, food.farmName].join("-")
            ] = { [customer]: extraQuantity };
          }
          // Update the quantity to be equal to the guaranteed quantity as that is the max
          // we can confirm until we check if there was any extra.
          communityOrdersTemp[customer].foodList[foodIndex].quantity =
            food.guaranteedMaxQuantity;
        }
      }
    });
  });

  // Set the database.
  const database = firebase.firestore();

  // Set a temp variable that we can update.
  const extraQuantitiesDictTemp = { ...extraQuantitiesDict };

  // Cycle through the food items that will have all the users that ordered
  // more than they were guaranteed.
  const readPromises = Object.keys(extraQuantitiesDict).map((foodItem) => {
    const foodItemSplit = foodItem.split("-");
    // Find the document name in FoodItems in the database.
    const documentName = foodItemSplit.join("").replace(/\s/g, "");
    // This will list all the users that need to have their orders
    // reviewed and this will be sorted by order time.
    const userExtrasList = [];
    // Add all the customers to the list.
    Object.keys(extraQuantitiesDict[foodItem]).forEach((customerOrder) => {
      userExtrasList.push(customerOrder);
    });
    // sort by order date so that the user that ordered first get the first
    // pick on the last few extras.
    userExtrasList.sort((a, b) => {
      const dateA = a.split("-")[3];
      const dateB = b.split("-")[3];
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }

      // dates are equal
      return 0;
    });

    // If the distribution location has ordered the item that has a limited
    // quantity then it will be guaranteed for it first and removed from the extras
    // pool of food.
    let basketFoodItemQuantity = 0;
    // Find the index of the foodItem in the distribution location's basket.
    const basketFoodItemIndex = basketFoodItems.findIndex(
      (distributionLocationFoodItem) => {
        return (
          foodItemSplit[0] === distributionLocationFoodItem.item &&
          foodItemSplit[1] ===
            distributionLocationFoodItem.individualDescription &&
          foodItemSplit[2] === distributionLocationFoodItem.farmName
        );
      },
    );

    // If the index exists then set the quantity to whatever the distribution
    // location has taken.
    if (basketFoodItemIndex !== -1) {
      basketFoodItemQuantity = basketFoodItems[basketFoodItemIndex].quantity;
    }

    // Create a document reference to the foodItem being updated.
    const foodItemsDocRef = database.collection("FoodItems").doc(documentName);
    return foodItemsDocRef.get().then((foodItemDoc) => {
      if (!foodItemDoc.exists) {
        // eslint-disable-next-line no-throw-literal
        throw "Document foodItem does not exist!" + foodItem;
      }
      // Create an updatedFoodItem document that we will use to update the
      // database with the changes made.
      const currentFoodItem = { ...foodItemDoc.data() };
      // The total extra of an item that were ordered  we'll use this
      // to deduct from the total farmlimit.
      let totalExtra = 0;
      // This is the index of the customer in userExtrasList.
      let index = 0;
      // This is the foodItem with the quantity we're removing.
      const foodItemRemoving = currentFoodItem;
      // Divide up whatever is left of this item amongst users that ordered
      // extra. Stop once we run out of the item or once all the orders have
      // been fulfilled.
      for (
        let totalAvailable = currentFoodItem.farmLimit - basketFoodItemQuantity;
        totalAvailable > 0 &&
        Object.keys(extraQuantitiesDictTemp[foodItem]).length > 0;
        totalAvailable--
      ) {
        //  Subtract one item owed to this person.  The total will be
        // however many left the user still wants.
        extraQuantitiesDictTemp[foodItem][userExtrasList[index]] -= 1;
        // Find the index of the foodItem in the community member's foodList.
        const foodItemIndex = communityOrders[
          userExtrasList[index]
        ].foodList.findIndex((customerFoodItem) => {
          return (
            foodItemSplit[0] === customerFoodItem.item &&
            foodItemSplit[1] === customerFoodItem.description &&
            foodItemSplit[2] === customerFoodItem.farmName
          );
        });

        // If the foodItem exists then add 1 to the quantity.
        if (foodItemIndex !== -1) {
          communityOrdersTemp[userExtrasList[index]].foodList[
            foodItemIndex
          ].quantity += 1;
        }

        // If the user's order has been fulfilled then remove them.
        if (extraQuantitiesDictTemp[foodItem][userExtrasList[index]] === 0) {
          delete extraQuantitiesDictTemp[foodItem][userExtrasList[index]];
          userExtrasList.splice(index, 1);
          // Subtract one from the index as the user doesn't exist anymore
          // and we don't want to skip out of order.
          index -= 1;
        }
        // Add to the index to serve the next customer.
        index += 1;
        // Once we've cycled through all the users start
        // from the top again. We want to serve everyone until
        // we've divided all the items or run out.
        if (index >= userExtrasList.length) {
          index = 0;
        }
        // Add to how many extra items we distributed.
        totalExtra += 1;
      }

      // Update the user's cash and credit volunteer fees.
      Object.keys(extraQuantitiesDictTemp[foodItem]).forEach((userOrder) => {
        const userOrderSplit = userOrder.split("-");

        foodItemRemoving.quantity =
          extraQuantitiesDictTemp[foodItem][userOrder];

        // We use this to calculate the new cash and credit portions of the volunteer fee after the
        // adjustments.
        // eslint-disable-next-line no-unused-vars
        const [userCashPortion, userCreditPortion, volunteerFeeChange] =
          VolunteerFeeAdjustment(
            communityOrdersTemp[userOrder],
            userOrderSplit[2],
            userInfo,
            foodItemRemoving,
            database,
            null,
            true,
          );
        communityOrdersTemp[userOrder].cashPortion = userCashPortion;
        communityOrdersTemp[userOrder].creditPortion = userCreditPortion;
      });
      // Update the totalExtra which we will use later to update the
      // total we're ordering from the farm.
      extraQuantitiesDictTemp[foodItem].totalExtra = totalExtra;
    });
  });
  // Once all the foodItems have finished then we can set the hooks
  // and combine all the orders.
  Promise.all(readPromises)
    .then(() => {
      setCommunityOrders({ ...communityOrdersTemp });
      setExtraQuantitiesDict(extraQuantitiesDict);
      AggregateCustomerList(
        communityOrdersTemp,
        setCommunityFoodItems,
        setCommunityFarmerInfo,
        setCommunityDonations,
        setCommunitySubsidies,
        false,
        setPantryFoodItems,
      );

      // Update and email users.
    })
    .catch((error) => {
      console.error("Error in transactions :", error);
    });
}

ExtraQuantitiesUpdate.propTypes = {
  communityOrders: PropTypes.object.isRequired,
  setCommunityFoodItems: PropTypes.func.isRequired,
  setCommunityFarmerInfo: PropTypes.func.isRequired,
  setCommunityDonations: PropTypes.func.isRequired,
  setCommunitySubsidies: PropTypes.func.isRequired,
  setPantryFoodItems: PropTypes.func.isRequired,
  setCommunityOrders: PropTypes.func.isRequired,
  setExtraQuantitiesDict: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
};

export default ExtraQuantitiesUpdate;
