// This is to show the community orders when it is coming from the myAccount page.
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import "../../../styles/MyAccount.css";
import ColourButton from "../../../components/userInputs/ColourButton.js";
import CustomerOrdersUpdateDialog from "../../../components/dialogs/CustomerOrdersUpdateDialog.jsx";
import AddCommunityMemberDialog from "../../../components/dialogs/AddCommunityMemberDialog.jsx";
import { AuthContext } from "../../../components/authentication/Auth.js";
import { ChangeOrderLogContext } from "../../MyAccount.js";
import CreateVisibleCommunityOrders from "../../../components/dialogs/functions/CreateVisibleCommunityOrders.js";
import OrderDownloadSelectionDialog from "../../../components/dialogs/Components/DownloadOrders/OrderDownloadSelectionDialog.jsx";
import UserReceiptMyAccount from "../Components/UserReceiptMyAccount.jsx";
import CommunityLocationStatus from "../../../components/dialogs/Components/CommunityLocationStatus.jsx";
import DistributedAmountReceipt from "../../../components/OrderDetails/DistributedAmountReceipt.jsx";

// This is the dialog box that opens when a user clicks on View Customer Receipts
function CommunityOrdersBox(props) {
  const { onClose, open } = props;
  // Whether the user is editing or not the community Orders.
  const [editing, setEditing] = useState(false);

  // These are what are currently being displayed this is either the simplified
  // orders where user's orders are combined into one or the full community orders
  // with each order separated
  const [commmunityOrdersDisplayed, setCommunityOrdersDisplayed] = useState({});

  // This hold the string that is used to print the orders in a doc
  const [downloadString, setDownloadString] = useState();

  // This hold the string that is used to print the orders in a doc in grid format.
  const [downloadGridString, setDownloadGridString] = useState();

  const { userInfo } = useContext(AuthContext);

  // Pulls the orderLog that contains an array of all the orders and their information
  // in a dictionary including;  communityOrders: {}, contactMethods: {}, farmerList: [{…}]
  // foodList: [{…}], importedDistributionDate: "MM-DD-YYYY", importedDistributionLocation: "XXX XXX",
  // importedOrder: {}, orderDate: "xxxxxx", selectedDate: "MM-DD-YYYY"
  const { orderLog, updateOrderLog } = useContext(ChangeOrderLogContext);

  // This is simply used as a variable to hold the value of the return of CreateVisibleCommunityOrders
  // that is then immediately inserted into commmunityOrdersDisplayed if the user
  // is just viewing the orders and not editing
  let communityOrders = {};

  const handleClose = () => {
    onClose(false);
  };

  // This creates the displayed community orders and auto sets them to be the simplified
  // orders
  useEffect(() => {
    communityOrders = CreateVisibleCommunityOrders(
      { ...props.communityOrders },
      setDownloadString,
      setDownloadGridString,
      editing,
      [],
      props.selectedDate,
    );

    setCommunityOrdersDisplayed({ ...communityOrders });
  }, [props.communityOrders]);

  // When the user clicks on the edit button to start editing the community orders
  // or to toggle to not editing
  const handleEditing = () => {
    // Decide whether to split up the community orders or have condense a users orders
    // and toggle the editing hook
    communityOrders = CreateVisibleCommunityOrders(
      { ...props.communityOrders },
      setDownloadString,
      setDownloadGridString,
      !editing,
      [],
      props.selectedDate,
    );
    setCommunityOrdersDisplayed({ ...communityOrders });
    setEditing(!editing);
  };

  // This is the date that order is picked up from customers
  let distributionDate = props.selectedDate;

  // If it is imported than the date is the importedDistributionDate
  if (props.imported) {
    distributionDate = props.order.importedDistributionDate;
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Community Orders for pickup on {distributionDate}{" "}
        <Button onClick={() => handleEditing()}>Edit Orders</Button>
        <span style={{ display: "flex", alignItems: "center" }}>
          <CustomerOrdersUpdateDialog
            communityOrders={props.communityOrders}
            location={props.location}
            selectedDate={props.selectedDate}
            showNames={props.showNames}
            imported={props.imported}
            order={props.order}
            orderIndex={props.orderIndex}
          />
          <AddCommunityMemberDialog distributionDate={distributionDate} />
        </span>
      </DialogTitle>
      <DialogContent>
        <p>
          <img
            style={{ marginRight: "5px", height: "15px" }}
            src="https://lh3.googleusercontent.com/pw/AM-JKLXnVzRoR0olkLyLIhES7m2SzKv6WY7tqNA3Gk5cX8fS0KwGGcquCaHe7SIATuy3mDuQwSYGi_XmlFHDQlOcMMG_oYch93BqJCZiV9CpnKobjJpnz9XR4PxRnAmpG7Eiv2aTsGpwgehBzLAUqHlixR7j=s512-no?authuser=1"
          />
          - Items Taken from the Pantry
        </p>
        <DistributedAmountReceipt
          distributedFoodItems={props.order.distributedFoodItems}
          location={props.location}
        />
        {Object.keys(commmunityOrdersDisplayed).map((location, i) => (
          <React.Fragment key={i}>
            <h4>
              <u>{location}</u>
              {location !== "Pickup On Site" && (
                <>
                  <CommunityLocationStatus
                    communityOrders={commmunityOrdersDisplayed[location]}
                    userInfo={userInfo}
                  />
                </>
              )}
            </h4>
            {Object.keys(commmunityOrdersDisplayed[location]).length !== 0 ? (
              <span>
                {Object.keys(commmunityOrdersDisplayed[location]).map(
                  (user, idx) => (
                    <UserReceiptMyAccount
                      idx={idx}
                      key={idx}
                      userOrder={commmunityOrdersDisplayed[location][user]}
                      user={user}
                      imported={props.imported}
                      order={props.order}
                      orderIndex={props.orderIndex}
                      userInfo={userInfo}
                      orderLog={orderLog}
                      updateOrderLog={updateOrderLog}
                      editing={editing}
                      location={props.location}
                      orderPaymentHistory={props.orderPaymentHistory}
                      distributionDate={distributionDate}
                    />
                  ),
                )}
              </span>
            ) : (
              <p>No Community Orders with this Order.</p>
            )}
          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          CLOSE
        </Button>
        {Object.keys(commmunityOrdersDisplayed).length !== 0 &&
          props.showNames && (
            <OrderDownloadSelectionDialog
              selectedDate={props.selectedDate}
              distributionLocation={props.location}
              downloadString={downloadString}
              downloadGridString={downloadGridString}
            />
          )}
      </DialogActions>
    </Dialog>
  );
}

CommunityOrdersBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function CommunityOrdersDialogMyAccount(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let location = "";
  let buttonTitle = "VIEW CUSTOMER RECEIPTS";
  let colour = "#17c917";
  let hoverColour = "#1c8c1c";
  if (props.imported) {
    location = props.location;
    buttonTitle = `View ${location} Receipts`;
    colour = "#f738a2";
    hoverColour = "#942161";
  } else {
    location = props.userInfo.organisationName;
  }

  return (
    <div>
      <div className="Order-Again-Button">
        <div className="Order-Again-Button">
          <ColourButton
            hoverColour={hoverColour}
            colour={colour}
            title={buttonTitle}
            onClick={handleClickOpen}
          />
        </div>
      </div>

      <CommunityOrdersBox
        open={open}
        onClose={handleClose}
        location={location}
        communityOrders={props.communityOrders}
        selectedDate={props.selectedDate}
        showNames={props.showNames}
        imported={props.imported}
        order={props.order}
        orderIndex={props.orderIndex}
        orderPaymentHistory={props.orderPaymentHistory}
      />
    </div>
  );
}
