// Displays more detailed information about the order cutoffs and includes informtion
// about the farmers cutoffs that might be different than the order cutoff of the distribution
// location itself.
import React, { useState } from "react";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";

// This function takes in the array of the orderCutoffs and then finds the day
// before them and creates a new array with them.  This is because the farm Cutoff
// is for the distribution location and the users need to send in their orders
// before this.
function FindDayBefore(orderCutoffDay) {
  // Used to convert the string to a number
  const daysOfTheWeek = {
    Mon: 1,
    Tues: 2,
    Wed: 3,
    Thurs: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };

  // Converts the number back to a day of the week string
  const daysOfTheWeekArray = [
    null,
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // This holds the new array with adjusted days that are the day prior
  const adjustedOrderCutoffDay = [];

  // Cycle through the farm's orderCutoff days and find each of theirs previous day
  orderCutoffDay.forEach((day) => {
    // subtract 1 to find the day prior
    let previousDay = daysOfTheWeek[day] - 1;

    // If the day is 0 than it was a Monday and we need to reset it to a Sunday
    // which is the last day on the array.
    if (previousDay === 0) {
      previousDay = 7;
    }
    // Find the day of the week in string format and add to the array
    adjustedOrderCutoffDay.push(daysOfTheWeekArray[previousDay]);
  });

  // Join together the array into a string separated by a comma and return the value
  return adjustedOrderCutoffDay.join(", ");
}

export default function OrderCutoffPopover({ farmDetails }) {
  // Set the anchor of where to place the popover.
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <HelpIcon
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ color: "red", marginRight: "5px", fontSize: "25px" }}
      />
      <Popper open={open} anchorEl={anchorEl} placement="left-start">
        <Box
          sx={{
            width: "400px",
            boxShadow:
              "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
            bgcolor: "background.paper",
          }}
        >
          <Typography sx={{ p: 1 }}>
            The marketplace below is up to date. The farm cutoff dates are for
            your own personal record and understanding. You may still order
            after a farm&apos;s cutoff but the selection will be limited and
            dependant on items that were bought in bulk.
            <br />
            <br />
            <u>Farm Cutoffs</u>
            {farmDetails.map((farm, i) => (
              <div
                key={i}
                style={{
                  margin: "0px",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <b>{farm.farmName} </b> : {FindDayBefore(farm.orderCutoffDay)}{" "}
                at midnight.
              </div>
            ))}
          </Typography>
        </Box>
      </Popper>
    </div>
  );
}
