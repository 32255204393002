// This dialog appears when a distribution location is changing a single order
// from a community order or from an imported order from another distribution location.
import React, { useContext } from "react";
import UpdateFoodItemsCommunityOrders from "../Functions/UpdateFoodItemsCommunityOrders.js";
import {
  ChangeOrderLogContext,
  CommunityOrderChunksContext,
} from "../../MyAccount.js";
import FoodItemInformationDialog from "../../../components/dialogs/Components/FoodItemInformationDialog.jsx";

// This is the dialog box that opens when a user clicks the edit button
export default function EditCommunityOrdersItemForm(props) {
  // This contains the orderLog and updateOrderLog so that when the user updates
  // the order it displays correctly on the myAccount Page
  // eslint-disable-next-line no-unused-vars
  const { orderLog, updateOrderLog, updatePaymentHistory } = useContext(
    ChangeOrderLogContext,
  );
  // communityOrderChunks are in {orderdate: [communityOrders, overFlowCommunityOrders1, etc.]}
  const { communityOrderChunks = [], setCommunityOrderChunks = () => {} } =
    useContext(CommunityOrderChunksContext) || {};

  // This is when the user saves to the database.
  const handleSave = (state, handleClose) => {
    // This sends in all the values the user could have changed that need to be
    // updated and updated the database.
    UpdateFoodItemsCommunityOrders(
      props,
      state,
      updatePaymentHistory,
      communityOrderChunks,
      setCommunityOrderChunks,
    );
    handleClose();
  };

  return (
    <div>
      <FoodItemInformationDialog
        buttonStyle={0}
        callSaveFunction={handleSave}
        {...props}
      />
    </div>
  );
}
