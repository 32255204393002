// This a dialog that pops up with editing a user's subsidy or donation amounts.
// It also allows for the changing of user to a subsidy or a donation.
import React, { useEffect, useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import SimplePopover from "../../components/userInputs/SimplePopover.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import UpdateCustomerContributionBasket from "./../ModifyOrders/UpdateCustomerContributionBasket.js";
import UpdateCustomerContribution from "./../ModifyOrders/UpdateCustomerContribution.js";
import UpdateCustomerContributionOld from "./../ModifyOrders/UpdateCustomerContributionOld.js";
import { CommunityOrderChunksContext } from "../../pages/MyAccount.js";
import "date-fns";

export default function SubsidyDonationChangeDialog(props) {
  const [open, setOpen] = useState(false);
  const [userOrder, setUserOrder] = useState({ ...props.userOrder });
  // communityOrderChunks are in {orderdate: [communityOrders, overFlowCommunityOrders1, etc.]}
  const { communityOrderChunks = [], setCommunityOrderChunks = () => {} } =
    useContext(CommunityOrderChunksContext) || {};

  // In case the props change the userOrder needs to be updated.
  useEffect(() => {
    setUserOrder({ ...props.userOrder });
  }, [props]);

  let dollarColour = "green";
  let textFieldColour = "primary";

  // Set the colours to red and secondary if the user is using a subsidy
  if (userOrder.customerContribution === "subsidy") {
    dollarColour = "red";
    textFieldColour = "secondary";
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // When the user saves their changes then this calls the functions to save to
  // the database.
  const handleSave = (e) => {
    e.preventDefault();
    // If handleCommunityOrderChange is defined then the call comes from the Basket
    // otherwise it come from the MyAccount Page.  Call these functions to update
    // the database with the changes.
    if (props.handleCommunityOrderChange !== undefined) {
      UpdateCustomerContributionBasket(props, userOrder);
    } else {
      // With the introduction of the multiple imported orders there is new way to
      // interact with the orders. June 17 2021
      if (props.order.orderDate > "202106150000000000000") {
        UpdateCustomerContribution(
          props,
          userOrder,
          props.updatePaymentHistory,
          communityOrderChunks,
          setCommunityOrderChunks,
        );
      } else {
        UpdateCustomerContributionOld(props, userOrder);
      }
    }
    setOpen(false);
  };

  // Updates the userOrder that came in but creates a temp one to change.  This is
  // used to change the radio button from subsidy to donation or vice versa
  const handleChange = (e) => {
    const userOrderTemp = { ...userOrder };
    userOrderTemp.donationSubsidy = 0;
    userOrderTemp.customerContribution = e.target.value;
    setUserOrder({ ...userOrderTemp });
  };

  // This updates the value that holds the donationSubsidy amount.  This is the
  // actual value.
  const updateDonationSubsidy = (e) => {
    // The total cost of the order is how much the order cost without the donation
    // or subsidy amounts added in.
    const costTotalTemp = props.costTotal;
    // The user's order which we will update.
    const userOrderTemp = { ...userOrder };

    // First check if the value is less than zero if it is then ignore it.
    if (parseFloat(e) < 0) {
      return null;
    }

    // if the user puts in a blank text then set the value to 0
    if (e === "") {
      userOrderTemp.donationSubsidy = parseFloat(0);
    } else {
      // if the user is donating then any value at this point will be fine
      if (userOrderTemp.customerContribution === "donation") {
        userOrderTemp.donationSubsidy = parseFloat(e);
      }
      // If they're using a subsidy we have to make sure the total isn't negative but
      // we want the user to be able to update it if there's a small amount under 0 because
      // of rounding.
      else if (costTotalTemp - e >= -0.0051) {
        userOrderTemp.donationSubsidy = parseFloat(e);
      } else {
        return null;
      }
    }

    setUserOrder({ ...userOrderTemp });
    return null;
  };

  return (
    <div>
      <Button size="small" color="primary" onClick={handleClickOpen}>
        Change
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          Change Subsidy/Donation
        </DialogTitle>
        {/* Reset the value of the donationSubsidy to 0 when the user
          changes to the subsidy radioButton.  This is to prevent mistakenly
          going into the negative if the value is bigger than what is owed */}
        <span className="Radio-TextField">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={userOrder.customerContribution}
            onChange={handleChange}
          >
            <span className="Info-And-Radio-Button">
              <SimplePopover content="Donations are collected to provide subsidies for others to buy food.  100% of the donations are used to directly subsidize guests that cannot afford to pay full price." />
              <FormControlLabel
                value="donation"
                control={<Radio />}
                label="Donation"
              />
            </span>
            <span className="Info-And-Radio-Button">
              <SimplePopover content="Subsidies are available to anyone that needs them.  These funds are sustained through the generosity of others in the community.  Please use them respectfully." />
              <FormControlLabel
                value="subsidy"
                control={<Radio />}
                label="Subsidy"
              />
            </span>
          </RadioGroup>
          <p
            className="Donation-Subsidy-Text-Field"
            style={{ color: dollarColour }}
          >
            : $
          </p>
          {
            <TextField
              value={userOrder.donationSubsidy}
              style={{ maxWidth: "60px" }}
              color={textFieldColour}
              type="number"
              onChange={(e) => {
                updateDonationSubsidy(e.target.value);
              }}
            />
          }
        </span>

        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
