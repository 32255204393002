// This updates the value that holds the donationSubsidy amount.  Called by individual
// basket.
export default function UpdateDonationSubsidy(
  event,
  customerContribution,
  setDonationSubsidy,
  costTotal,
  setShake,
  packageFeeTotal,
  deliveryFee,
  cashPortion,
  subsidyAmount,
) {
  // This resets the shake class to nothing.  That way when the user changes to an amount
  // that is allowed the class is different and does not shake but if they were to try and
  // go over again then it will shake again.  It needs to be reset to allow this to happen.
  setShake("nothing");

  // This is the total cost of the food plus the total fees paid.  Users can
  // use their subsidy against the fees.
  const totalCostIncFees =
    packageFeeTotal +
    costTotal +
    parseFloat(deliveryFee) +
    parseFloat(cashPortion);
  // First check if the value is less than zero if it is then ignore it.
  if (parseFloat(event) < 0) {
    return null;
  }

  // if the user puts in a blank text then set the value to 0
  if (event === "") {
    setDonationSubsidy(parseFloat(0));
  } else {
    // if the user is donating then any value at this point will be fine
    if (customerContribution === "donation") {
      setDonationSubsidy(parseFloat(event));
    }
    // If they're using a subsidy we have to make sure the total isn't negative
    // or greater than the subsidy amount this distribution location offers.
    else if (
      totalCostIncFees - event >= -0.0051 &&
      subsidyAmount - event >= -0.0051
    ) {
      setDonationSubsidy(parseFloat(event));
    }
    // If the user has gone over the allowed subsidy amount then shake the shake
    // hook to the className that will make the max subsidy line on the website shake.
    else if (event > subsidyAmount) {
      setShake("Max-Subsidy-Shake-Animation");
    }
  }
  return null;
}
