// This contains the icons we use in the project.
import React from "react";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { Typography } from "@mui/material";

// Define a styled wrapper for the icons
const IconWrapper = ({ children }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%", // Makes it circular
      border: "1px solid gray", // Border color
      width: "20px", // Width of the circle
      height: "20px", // Height of the circle
    }}
  >
    {children}
  </div>
);

// Define an object for icons and typography
export const Icons = {
  // For tagged fridge items
  fridge: (
    <IconWrapper>
      <Typography variant="h6" style={{ fontSize: "14px" }}>
        F
      </Typography>
    </IconWrapper>
  ),
  // For tagged frozen items
  frozen: (
    <IconWrapper>
      <AcUnitIcon fontSize="small" />
    </IconWrapper>
  ),
};

// Export a function to get icon by key
export const getIconByKey = (key) => {
  switch (key) {
    case 0: // Fridge
      return Icons.fridge;
    case 1: // Frozen
      return Icons.frozen;
    default:
      return null; // Handle other cases if needed
  }
};
