// This is a dialog that takes in the customer FoodList and the orderFoodList and
// then compares them to determine how much food was distributed and how much
// was preordered and how much was put into the pantry and displays it to the user.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import "../../../styles/MyAccount.css";
import { Grid } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DisplayLinearBar from "../Components/DisplayStats/DisplayLinearBar.jsx";
import SortFilter from "../Components/SortFilter.jsx";
import { getIconByKey } from "../../../images/Icons"; // Import the function to get icons

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "0px 15px 0px 15px",
  },
}));

function FoodAmountStatsMyAccountBox(props) {
  const classes = useStyles();

  const { onClose, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  // Sets the orderFoodList to the total food ordered from the farm
  const orderFoodList = [...props.orderFoodList];

  // Sets the pantryFoodItems to an empty array.
  let pantryFoodItems = [];
  // If there are pantryFoodItems then set them to those.
  if (props.pantryFoodItems) {
    pantryFoodItems = [...props.pantryFoodItems];
  }

  // Goes through the customer foodList and adds any items that weren't ordered
  // but were pre ordered to the orderFoodList. Once that has been added then cycle
  // through it again and check to see if any pantry items have been ordered by customers
  // and not added.  If there are any missing it will indicate them as having a missing source.
  for (let x = 0; x < 2; x++) {
    // The customer food List is all the food items that the community orders have ordered
    // fresh.
    let customerList = props.customerFoodList;
    // foodList are the items that were ordered this week.
    let orderList = orderFoodList;
    // If x is 1 then we know that it is the pantry's turn and we'll set the values
    // to those amounts.
    if (x === 1) {
      customerList = props.customerPantryList;
      orderList = pantryFoodItems;
    }

    // Cycle through the customer foodList and check all the items.
    customerList.forEach((foodItem) => {
      // Finds the index of the orderFoodList item that matches the customer foodList
      // item if it exists.
      const index = orderList.findIndex((orderFoodItem) => {
        return (
          orderFoodItem.item === foodItem.item &&
          orderFoodItem.individualDescription ===
            foodItem.individualDescription &&
          orderFoodItem.farmName === foodItem.farmName &&
          !!orderFoodItem.limit === !!foodItem.limit
        );
      });

      // If the item doesn't exist then add it to the orderFoodList and set the quantity
      // to 0 since it wasn't ordered and set the flag missing source to true.
      if (index === -1) {
        orderList.push({ ...foodItem });
        orderList[orderList.length - 1].quantity = 0;
        orderList[orderList.length - 1].missingSource = true;
      }
    });
  }

  return (
    <Dialog
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Food Distribution Stats
      </DialogTitle>
      {pantryFoodItems.length > 0 && (
        <p style={{ margin: "15px" }}>
          <img
            style={{ marginRight: "5px", height: "15px" }}
            src="https://lh3.googleusercontent.com/pw/AM-JKLXnVzRoR0olkLyLIhES7m2SzKv6WY7tqNA3Gk5cX8fS0KwGGcquCaHe7SIATuy3mDuQwSYGi_XmlFHDQlOcMMG_oYch93BqJCZiV9CpnKobjJpnz9XR4PxRnAmpG7Eiv2aTsGpwgehBzLAUqHlixR7j=s512-no?authuser=1"
          />
          - Items Taken from the Pantry
        </p>
      )}
      {!props.editing && (
        <div style={{ margin: "15px" }}>
          <SortFilter
            foodList={orderFoodList}
            setFoodList={props.setFoodList}
            originalFoodList={[...props.orderLog[props.orderIndex].foodList]}
            pantryFoodItems={pantryFoodItems}
            setPantryFoodItems={props.setPantryFoodItems}
            originalPantryFoodItems={[
              ...props.orderLog[props.orderIndex].pantryFoodItems,
            ]}
          />
        </div>
      )}
      <div className={classes.root}>
        {orderFoodList.map((foodItem, i) => (
          <Grid key={i} container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <p style={{ fontSize: "15px" }}>{foodItem.item}</p>
              {foodItem.packageFee > 0 && (
                <p style={{ fontSize: "12px", color: "red" }}>
                  <u>Packaging Fee</u>
                  <br />${foodItem.packageFee}
                </p>
              )}
              <p style={{ fontSize: "10px" }}>({foodItem.farmName})</p>
              {/* Check if food.tagList exists and has items */}
              {foodItem.tagList && foodItem.tagList.length > 0 && (
                <div style={{ display: "inline-flex", marginLeft: "8px" }}>
                  {foodItem.tagList.map((tag) => (
                    <span key={tag.key} style={{ marginRight: "4px" }}>
                      {getIconByKey(tag.key)}{" "}
                      {/* Render the icon for each tag */}
                    </span>
                  ))}
                </div>
              )}
            </Grid>
            <Grid item xs>
              <DisplayLinearBar
                foodItem={foodItem}
                preOrderedFoodList={props.customerFoodList}
                orderFoodList={props.orderFoodList}
                userInfo={props.userInfo}
                orderLog={props.orderLog}
                orderIndex={props.orderIndex}
                updateOrderLog={props.updateOrderLog}
              />
            </Grid>
          </Grid>
        ))}
        {pantryFoodItems.map((foodItem, i) => (
          <Grid key={i} container alignItems="center" spacing={2}>
            <Grid item xs={3}>
              <span style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "5px", height: "15px" }}
                  src="https://lh3.googleusercontent.com/pw/AM-JKLXnVzRoR0olkLyLIhES7m2SzKv6WY7tqNA3Gk5cX8fS0KwGGcquCaHe7SIATuy3mDuQwSYGi_XmlFHDQlOcMMG_oYch93BqJCZiV9CpnKobjJpnz9XR4PxRnAmpG7Eiv2aTsGpwgehBzLAUqHlixR7j=s512-no?authuser=1"
                />
                <p style={{ fontSize: "15px" }}>{foodItem.item}</p>
              </span>
              <p style={{ fontSize: "10px" }}>({foodItem.farmName})</p>
              {/* Check if food.tagList exists and has items */}
              {foodItem.tagList && foodItem.tagList.length > 0 && (
                <div style={{ display: "inline-flex", marginLeft: "8px" }}>
                  {foodItem.tagList.map((tag) => (
                    <span key={tag.key} style={{ marginRight: "4px" }}>
                      {getIconByKey(tag.key)}{" "}
                      {/* Render the icon for each tag */}
                    </span>
                  ))}
                </div>
              )}
            </Grid>
            <Grid item xs>
              <DisplayLinearBar
                foodItem={foodItem}
                preOrderedFoodList={props.customerPantryList}
                orderFoodList={props.pantryFoodItems}
                userInfo={props.userInfo}
                orderLog={props.orderLog}
                orderIndex={props.orderIndex}
                updateOrderLog={props.updateOrderLog}
                pantryItems={true}
              />
            </Grid>
          </Grid>
        ))}
      </div>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FoodAmountStatsMyAccountBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function FoodAmountStatsMyAccountDialog({
  pantryFoodItems,
  customerPantryList,
  orderFoodList,
  customerFoodList,
  userInfo,
  orderLog,
  orderIndex,
  updateOrderLog,
  setFoodList,
  setPantryFoodItems,
  editing,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div>
        <div>
          <Button color="primary" onClick={handleClickOpen}>
            View Volumes of Pre-Ordered and Distributed Food
          </Button>
        </div>
      </div>
      <FoodAmountStatsMyAccountBox
        open={open}
        onClose={handleClose}
        pantryFoodItems={pantryFoodItems}
        customerPantryList={customerPantryList}
        orderFoodList={orderFoodList}
        customerFoodList={customerFoodList}
        userInfo={userInfo}
        orderLog={orderLog}
        orderIndex={orderIndex}
        updateOrderLog={updateOrderLog}
        setFoodList={setFoodList}
        setPantryFoodItems={setPantryFoodItems}
        editing={editing}
      />
    </div>
  );
}
