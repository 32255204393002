// This dialog appears when the distribution location user is adding a
// user to a community order.  They can search for the user's name.
import React, { useState } from "react";
import firebase from "../../components/Firebase.js";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingContent from "../../components/LoadingContent.jsx";
import AddCommunityOrderButton from "./Components/AddCommunityOrderButton.jsx";

// Search for a user that and their information to add them to the volunteer food system.
function SearchForUser(searchName, setCommunityMembersFound, setSearchLoading) {
  const database = firebase.firestore();
  const names = searchName.split(" ");
  // Community Member temporary.
  const communityMembersFoundTemp = {};
  // Search loading.
  setSearchLoading(false);

  // If the first name is undefined then set it to nothing.
  if (names[0] === undefined) {
    names[0] = "";
  }

  // If the last name is undefined then set it to nothing.
  if (names[1] === undefined) {
    names[1] = "";
  }

  // Set the communityMember doc ref.
  let communityMemberDocRef = database
    .collection("Users")
    .where("firstName", "==", names[0]);

  // If the last name is not equal to nothing then search for the first and last name.
  if (names[1] !== "") {
    // Load the volunteer positions from the database and add them to the hook volunteerJobs.
    communityMemberDocRef = database
      .collection("Users")
      .where("firstName", "==", names[0])
      .where("lastName", "==", names[1]);
  }

  // Load the user's user doc and then add their information to be used.
  communityMemberDocRef
    .get()
    .then((collection) => {
      collection.docs.forEach((doc) => {
        if (doc.exists) {
          const dictKey = [doc.data().userId].join("-");
          communityMembersFoundTemp[dictKey] = { ...doc.data() };
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });

      // Sort CommunityMembers after fetching
      const sortedCommunityMembers = Object.keys(
        communityMembersFoundTemp,
      ).sort((a, b) => {
        const nameA =
          communityMembersFoundTemp[a].firstName.toLowerCase() +
          communityMembersFoundTemp[a].lastName.toLowerCase();
        const nameB =
          communityMembersFoundTemp[b].firstName.toLowerCase() +
          communityMembersFoundTemp[b].lastName.toLowerCase();
        return nameA.localeCompare(nameB);
      });

      setCommunityMembersFound(
        sortedCommunityMembers.reduce((acc, key) => {
          acc[key] = communityMembersFoundTemp[key];
          return acc;
        }, {}),
      );
      setSearchLoading(true);
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
      setSearchLoading(true);
    });
}

// This is the dialog box that opens when a user clicks the add button
function AddCommunityMemberBox({ open, onClose, distributionDate }) {
  // The name of the user you are searching for.
  const [searchName, setSearchName] = useState("");
  // The search is loading.
  const [searchLoading, setSearchLoading] = useState(true);
  // The volunteers found from the search
  const [communityMembersFound, setCommunityMembersFound] = useState({});

  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <style>
        {`
            .volunteer-list {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 8px 0;
            }
            .volunteer-details {
              flex-grow: 1;
            }
          `}
      </style>
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Add Community Members
      </DialogTitle>
      <DialogContent>
        <div>
          <div className="search-bar">
            <style>
              {`
                    .search-bar {
                      display: flex;
                      align-items: center;
                      gap: 10px; /* Adds space between the TextField and the Button */
                    }

                    .search-input {
                      flex-grow: 1; /* Makes the input take the remaining space */
                    }
                  `}
            </style>

            <TextField
              margin="dense"
              id="searchName"
              label="Volunteer Search"
              name="searchName"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              className="search-input"
              required
            />
            <Button
              onClick={() =>
                SearchForUser(
                  searchName,
                  setCommunityMembersFound,
                  setSearchLoading,
                )
              }
              variant="contained"
              size="small"
              color="primary"
            >
              ENTER
            </Button>
          </div>

          {searchLoading ? (
            <div>
              {Object.keys(communityMembersFound).map(
                (communityMemberFound, index) => (
                  <div key={communityMemberFound}>
                    <div className="volunteer-list">
                      <span className="volunteer-details">
                        {communityMembersFound[communityMemberFound].firstName}{" "}
                        {communityMembersFound[communityMemberFound].lastName}
                        <br />
                        Email:{" "}
                        {communityMembersFound[communityMemberFound].email}
                      </span>
                      {communityMembersFound[communityMemberFound]
                        .pickupLocation &&
                      Object.keys(
                        communityMembersFound[communityMemberFound]
                          .pickupLocation,
                      ).length > 0 ? (
                        <AddCommunityOrderButton
                          userInfo={communityMembersFound[communityMemberFound]}
                          distributionDate={distributionDate}
                        />
                      ) : (
                        <Button
                          variant="contained"
                          disabled
                          style={{ backgroundColor: "#ccc", color: "white" }}
                        >
                          No pickup location chosen
                        </Button>
                      )}
                    </div>
                    {index !==
                      Object.keys(communityMembersFound).length - 1 && (
                      <hr
                        style={{
                          width: "100%",
                          margin: "10px 0",
                          border: "1px solid #333",
                        }}
                      />
                    )}
                  </div>
                ),
              )}
            </div>
          ) : (
            <LoadingContent inline={true} />
          )}
        </div>

        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            CLOSE
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

AddCommunityMemberBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function AddCommunityMemberDialog({ distributionDate }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        style={{ backgroundColor: "green", color: "white" }}
        variant="contained"
        onClick={handleClickOpen}
      >
        ADD COMMUNITY MEMBER
      </Button>

      <AddCommunityMemberBox
        open={open}
        onClose={handleClose}
        distributionDate={distributionDate}
      />
    </div>
  );
}
