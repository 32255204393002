// This is to add a new foodItem to a distribution location's order.
import firebase from "./../Firebase.js";
import moment from "moment";

export default function AddFoodItem(
  state,
  order,
  userInfo,
  orderLog,
  updateOrderLog,
  orderIndex,
) {
  const database = firebase.firestore();
  const batch = database.batch();
  // Create a reference to the distribution location's order for the selected order
  const docRef = database
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("Orders")
    .doc(order.orderDate);

  // This is used to store the food items
  const foodListTemp = [...order.foodList];

  // This is to be updated so that the array that holds all the orders from myAccount
  // is correct with the changes.
  const orderLogTemp = [...orderLog];

  const contactMethodsTemp = { ...order.contactMethods };

  // This contains dictionaries of the farms in an array.
  const farmerListTemp = [...order.farmerList];

  const foodItem = { ...state };
  foodItem.userId = firebase.auth().currentUser.uid;
  // This is hardcoded to match deprecated code
  foodItem.userType = "admin";

  // Add the foodItem to the foodList
  foodListTemp.push(foodItem);

  // This is used to find the current farmIndex from the farmerList.  It is used
  // to add the dictionary of the farm to the ingredient List under supplyingFarm.
  const farmIndex = farmerListTemp.findIndex((farm) => {
    return farm.farmName === state.farmName;
  });

  // If the farm is already included in the order than just update the foodList
  if (farmIndex !== -1) {
    batch.update(docRef, {
      foodList: [...foodListTemp],
    });

    orderLogTemp[orderIndex].foodList = [...foodListTemp];

    // This is to create an ingredient item and to save it to the ingredient List
    // collection in the database
    const documentName = (
      state.item +
      moment(state.selectedDate).format().substring(0, 10) +
      firebase.auth().currentUser.uid
    ).replace(/\s+/g, "");
    const ingredientListDocRef = database
      .collection("IngredientList")
      .doc(documentName);

    // Add the foodItem to the ingredient List.  This may overwrite one that is already
    // there but it will be a copy and so doesn't matter
    batch.set(ingredientListDocRef, {
      deliveredOn: state.selectedDate.toDateString(),
      deliveredOnISO: moment(state.selectedDate).format().substring(0, 10),
      ingredient: state.item,
      // NEEDS WORK
      // harvestedOn: orderCutoffDay.toDateString(),
      supplyingFarm: farmerListTemp[farmIndex],
      restaurantName: userInfo.organisationName,
      restaurantAddress:
        userInfo.restaurantAddress +
        ", " +
        userInfo.restaurantCity +
        ", " +
        userInfo.restaurantProvTerr +
        " " +
        userInfo.restaurantPostalCode,
      restaurantLatitude: userInfo.restaurantLatitude,
      restaurantLongitude: userInfo.restaurantLongitude,
      userType: userInfo.userType,
      userId: firebase.auth().currentUser.uid,
    });
    batch.commit();

    // Updates the orderLog to make sure the screen shows the correct information
    updateOrderLog(orderLogTemp);
  } else {
    // Add the new farm to the contact methods.  It is defaulted to Email
    contactMethodsTemp[state.farmName] = "Email";
    // Pull the farm's details from the database
    const farmDocRef = database.collection("Farmers").doc(state.farmName);
    farmDocRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // Add this farm to the farmerList
          farmerListTemp.push(doc.data());
          // Update the distribution Location's order to include the new foodItem,
          // farmer and update the contactMethods
          batch.update(docRef, {
            foodList: [...foodListTemp],
            contactMethods: { ...contactMethodsTemp },
            farmerList: [...farmerListTemp],
          });

          // This is to make sure the page rerenders these items
          orderLogTemp[orderIndex].foodList = [...foodListTemp];
          orderLogTemp[orderIndex].contactMethods = { ...contactMethodsTemp };
          orderLogTemp[orderIndex].farmerList = [...farmerListTemp];

          // We still have to add this item to the ingredient List.   You can't do this
          // until the data is loaded from the database about the farm
          const documentName = (
            state.item +
            moment(state.selectedDate).format().substring(0, 10) +
            firebase.auth().currentUser.uid
          ).replace(/\s+/g, "");
          const ingredientListDocRef = database
            .collection("IngredientList")
            .doc(documentName);

          // This is essentially the same for distribution Locations and other organizations
          // except that the other Organisations have different names for things.
          batch.set(ingredientListDocRef, {
            deliveredOn: state.selectedDate.toDateString(),
            deliveredOnISO: moment(state.selectedDate)
              .format()
              .substring(0, 10),
            ingredient: state.item,
            // NEEDS WORK
            // harvestedOn: orderCutoffDay.toDateString(),
            supplyingFarm: doc.data(),
            restaurantName: userInfo.organisationName,
            restaurantAddress:
              userInfo.restaurantAddress +
              ", " +
              userInfo.restaurantCity +
              ", " +
              userInfo.restaurantProvTerr +
              " " +
              userInfo.restaurantPostalCode,
            restaurantLatitude: userInfo.restaurantLatitude,
            restaurantLongitude: userInfo.restaurantLongitude,
            userType: userInfo.userType,
            userId: firebase.auth().currentUser.uid,
          });
          batch.commit();
          // Updates the orderLog to make sure the screen shows the correct information
          updateOrderLog(orderLogTemp);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }

  console.log("Successfully wrote to database!");
}
