// This is used to display information about a food element on the marketplace.
// It is called by all marketplaces except the restaurant marketplace.
import React from "react";
import firebase from "./../../../components/Firebase.js";
import RemoveCategoryFromDistributionLocations from "./../../../functions/RemoveCategoryFromDistributionLocations.js";
import HandleQuantityChange from "./../Functions/HandleQuantityChange.js";
import HandleBasketItems from "./../Functions/HandleBasketItems.js";
import HandleRefresh from "./../Functions/HandleRefresh.js";
import UpdateVisibility from "./../Functions/UpdateVisibility.js";
import CreateFarmCategories from "./../../../functions/CreateFarmCategories.js";
import FoodCard from "./FoodCard.jsx";

export default function FoodElement({
  index,
  row,
  currentUser,
  userInfo,
  handleUserInfoChange,
  displayedFoodItems,
  setDisplayedFoodItems,
  farmCategories,
  foodItems,
  setFoodItems,
  setSnackBarOpen,
  setSnackBarMessage,
}) {
  // Increments the item on the marketplace
  const incrementItem = () => {
    // If the user has ordered more then the limit then we set this to true and
    // prevent them from ordering anymore.
    let farmLimited = false;
    // If the user is an inidividual then we use the quantity and compare it to the
    // the farmLimit which is denominated in individualQuantity.
    if (userInfo.userType === "individual") {
      // If the difference is less then 1 then we know that the item has gone over
      // the amount available and we have to limit the user from ordering more.
      if (
        row.farmLimit !== undefined &&
        row.farmLimit !== "" &&
        row.farmLimit - row.quantity < 1
      ) {
        farmLimited = true;
      }
    }
    // If the user is a distribution location then we need to convert the farmLimit
    // to the distribution location's amounts and compare if the user can add more
    // of the item to their order.
    else {
      if (
        row.farmLimit !== undefined &&
        row.farmLimit !== "" &&
        (row.farmLimit * row.individualQuantity) / row.distributionQuantity -
          row.quantity <
          1
      ) {
        farmLimited = true;
      }
    }

    // make sure that the user hasn't reached the limit if  there is one of leftover items.
    // If there is not limit then the user is fine.  If the limit exists then we don't care
    // about the farmLimit since we're taking from the pantry not ordering from the farmer.
    if (
      ((row.limit === undefined || row.limit === "") && !farmLimited) ||
      (row.limit !== undefined &&
        row.limit !== "" &&
        row.limit - row.quantity >= 1)
    ) {
      HandleQuantityChange(
        displayedFoodItems,
        setDisplayedFoodItems,
        true,
        (row.item + row.description + row.farmName).replace(/\s+/g, ""),
        false,
        userInfo,
      );
    }
  };

  // Decreases the quantity of an item on the marketplace
  const decreaseItem = () => {
    // eslint-disable-next-line no-empty
    if (row.quantity === 0) {
    } else {
      HandleQuantityChange(
        displayedFoodItems,
        setDisplayedFoodItems,
        false,
        (row.item + row.description + row.farmName).replace(/\s+/g, ""),
        false,
        userInfo,
      );
    }
  };

  // When a user adds an item to the basket individually.
  const handleAddToBasket = () => {
    // Makes sure the row isn't empty.
    if (row.quantity !== 0) {
      // Here we need to update the user's basketItems so we call this function
      // which takes the index of the row and returns the new basketItems and the
      // documentName of the current User's document.  We then update the database
      HandleBasketItems(
        userInfo,
        displayedFoodItems,
        index,
        false,
        row.quantity,
        handleUserInfoChange,
      );

      // We have to update the quanities of the foodItem so that the quantity is reset to 0
      // we do a callback to update these.
      HandleQuantityChange(
        displayedFoodItems,
        setDisplayedFoodItems,
        true,
        (row.item + row.description + row.farmName).replace(/\s+/g, ""),
        true,
        userInfo,
      );
      setSnackBarMessage("Added " + row.item + " to your cart");
      setSnackBarOpen(true);
      console.log("Added to Basket");
    }
  };

  // For admin user only when they delete an item.
  const handleDelete = () => {
    const documentName = (row.item + row.description + row.farmName).replace(
      /\s+/g,
      "",
    );
    const database = firebase.firestore();

    database
      .collection("FoodItems")
      .doc(documentName)
      .delete()
      .then(function () {})
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });

    // Here we need to update the user's basketItems so we call this function
    // which takes the index of the row and a boolean to tell it we are deleting
    // something. It returns the new basketItems and the documentName of the
    // current User's document.  We then update the database.
    HandleBasketItems(
      userInfo,
      displayedFoodItems,
      index,
      true,
      row.quantity,
      handleUserInfoChange,
    );
    const foodItemsNew = HandleRefresh(
      foodItems,
      setFoodItems,
      (row.item + row.description + row.farmName).replace(/\s+/g, ""),
      false,
    );

    // Creates the updated farm category list.
    const newFarmCategories = CreateFarmCategories(foodItemsNew);

    // Checks and removes a category from all distribution locations that use this
    // farm if this item was the only item from this category.
    RemoveCategoryFromDistributionLocations(
      newFarmCategories,
      row.farmName,
      row.category,
    );
  };

  // If the user has selected to switch the visibility then call the update visibility
  // function.
  const handleSwitch = (e) => {
    const checked = e.target.checked;
    UpdateVisibility(userInfo, row, checked, foodItems, setFoodItems);
  };

  // Used to display the text on the addbutton
  const updateButton = "ADD TO CART";

  // The default image to display if this element has no image.
  let displayImage =
    "https://lh3.googleusercontent.com/Issibzc2aRSgeCb-ZEvy2FdRek0t_76tclQJR7sZ64UFX2BHJPi3bXlBGpC_CAMDlwvb9jx_UPanf5jf0lKhXqd2qUqs1JvrEnRohV8Rv0-Qc84mgzMPe4FRYRvAuoO-5oj5gG1cps6KS7pTPh-hVJHjUCFmoUJt_mP1XYf-7ToSp16li0EoxMrTf6XOcRXq4t1D4QNOsy0HFR67bPCz_aFLzAnGGRBn8fYeg6ZDBrEA_bmJT574DSGQ9KVPE8xbJIGsbAXg7gkak6w-kUwR9Zh43UoE_R7fMeH7N3Ck2zGUPz8fk2gqHT_PlzQ_8gSDlmvNj8XJexH_PofD_fvBF3qfJW5pDO124JmKKlgdbNzXLM0VUpkoUH95mOVkblwpQzG4LJ-GXctSk-ycY1ikWj_xC_R1uyz8drfdXkqtIC0lyWhNwtVzqlZmHjTsOBVsiyaeqvtmFDZetEvuXm8vYTI4bIAa89eXH1Zt81g9XKVfubR8_eQH-3DNAC3Jam84_ro_38rZwxqI--HcJzgj1zcjV6FLCyFeegovwtSxC3VX70XZFUH4Qlv7bBfRK4CCs6lg-AlNgIYmnAr2Jjik9oL2y567f4zK_VROoVH7994c2QJ4S0QhX3-KntKrgIaxJxdrDk-6dayyMprKCI-ZXfF7uT3qk1oDR1HVBkW1G_Y60jyZvWL-dtoS-m1rzr5DrRTtYpxcdKVIMWuHbbGAGlQ=s512-no?authuser=2";

  // If the foodItem does have an image to display it will display it instead.
  if (!(row.displayImage === undefined || row.displayImage === "")) {
    displayImage = row.displayImage;
  }

  // Let the visibility switch be set to whatever the adminVisible is.
  let visible = row.adminVisible;

  // If the user is a distribution location.
  if (userInfo.userType === "distributionLocation") {
    // Set the userId of the location.
    const locationUserId = userInfo.userId;
    // Check to see if distributionLocationVisible exists.
    if (row.distributionLocationVisible) {
      // Check to see if this distribution location has set this item to be visibile
      // on their own marketplace to individuals.
      if (row.distributionLocationVisible.includes(locationUserId)) {
        // set it to true
        visible = true;
      } else {
        // set visibility to individual users to false.
        visible = false;
      }
    } else {
      // If there is no distributionLocationVisible then set it to false by default.
      visible = false;
    }
  }

  return (
    <FoodCard
      displayImage={displayImage}
      index={index}
      row={row}
      currentUser={currentUser}
      userInfo={userInfo}
      handleUserInfoChange={handleUserInfoChange}
      displayedFoodItems={displayedFoodItems}
      setDisplayedFoodItems={setDisplayedFoodItems}
      incrementItem={incrementItem}
      decreaseItem={decreaseItem}
      handleAddToBasket={handleAddToBasket}
      updateButton={updateButton}
      visible={visible}
      handleSwitch={handleSwitch}
      handleDelete={handleDelete}
      foodItems={foodItems}
      setFoodItems={setFoodItems}
      farmCategories={farmCategories}
    />
  );
}
