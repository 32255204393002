// This is used to add an item to either an order placed by a distribution location,
// change the community or imported orders on the MyAccount Page or on the Basket
// Page.
import React, { useState, useEffect, useContext } from "react";
import AddFoodItem from "./../ModifyOrders/AddFoodItem.js";
import AddFoodItemCommunityOrdersBasket from "./../ModifyOrders/AddFoodItemCommunityOrdersBasket.js";
import AddFoodItemCommunityOrders from "./../ModifyOrders/AddFoodItemCommunityOrders.js";
import "date-fns";
import LoadMarketplaceItems from "./../../functions/LoadMarketplaceItems.js";
import { CommunityOrderChunksContext } from "../../pages/MyAccount.js";
import FoodItemInformationDialog from "../../components/dialogs/Components/FoodItemInformationDialog.jsx";

export const SetVariablesContext = React.createContext();

export default function AddItemToOrderDialog(props) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  // The list of all the food Items available.
  const [foodItemsToSelect, setFoodItemsToSelect] = useState([]);
  // The foodItems that are being shown in case the user has selected
  // a farm to be removed.
  const [displayedFoodItemsToSelect, setDisplayedFoodItemsToSelect] = useState(
    [],
  );
  // The farmers that exist and provide the foodItems.
  const [farmersAvailable, setFarmersAvailable] = useState([]);
  // communityOrderChunks are in {orderdate: [communityOrders, overFlowCommunityOrders1, etc.]}
  const { communityOrderChunks = [], setCommunityOrderChunks = () => {} } =
    useContext(CommunityOrderChunksContext) || {};

  const [addToDistributionLocation, setAddToDistributionLocation] =
    useState(true);

  useEffect(() => {
    // If this is undefined we know the user is adding an item to the distribution
    // location's order.  When we set addToDistributionLocation to false then we won't
    // show the calendar or the limit fields.
    if (props.userOrder !== undefined) {
      setAddToDistributionLocation(false);
    }
    // Load the foodItems that are available for the user to select
    // from to add if they would like to autofill.
    // If the length is 0 then we haven't loaded the foodItems yet.
    // We want it to be open so that we don't end up with a bunch
    // of loads when we only need to do it once when the user opens.
    if (foodItemsToSelect.length === 0 && open) {
      // Load foodItemsToSelect
      LoadMarketplaceItems(
        null,
        1000,
        true,
        {},
        setFoodItemsToSelect,
        setDisplayedFoodItemsToSelect,
        null,
        setLoading,
        props.userInfo,
        {},
      );
    }
    // If the length is greater than 0 then we have already loaded
    // the foodItems and can just set loading to true.
    else if (foodItemsToSelect.length > 0 && open) {
      setLoading(true);
    }
  }, [open]);

  // Once we have the list of foodItems then we have to set the list
  // of farmers available so the user can toggle the items from each farm
  // on or off.
  useEffect(() => {
    //  Make sure the foodItems exist and that the user has opened
    // the dialog.
    if (foodItemsToSelect.length > 0 && open) {
      const farmersAvailableTemp = [];
      // Cycle through all the foodItems and add the farms
      // to the list.  Some items will come from the same
      // farm so check if it's already been added.
      foodItemsToSelect.forEach((foodItem) => {
        if (!farmersAvailableTemp.includes(foodItem.farmName)) {
          farmersAvailableTemp.push(foodItem.farmName);
        }
      });
      farmersAvailableTemp.sort();
      setFarmersAvailable([...farmersAvailableTemp]);
    }
  }, [foodItemsToSelect, open]);

  const handleSave = (state, handleClose) => {
    // If the userOrder is undefined then we know that we are adding to the distribution
    // location's order.  If handleCommunityOrderChange is defined we know the call came
    // from Basket and so handle it from there otherwise it's coming from a community or
    // imported order.
    if (props.userOrder === undefined) {
      AddFoodItem(
        state,
        props.order,
        props.userInfo,
        props.orderLog,
        props.updateOrderLog,
        props.orderIndex,
      );
    } else if (props.handleCommunityOrderChange !== undefined) {
      // With the introduction of the multiple imported orders there is new way to
      // interact with the orders.
      AddFoodItemCommunityOrdersBasket(
        props.idx,
        props.userOrder,
        props.user,
        props.imported,
        props.importedOrder,
        props.location,
        props.selectedDate,
        state,
        props.userInfo,
        props.handleCommunityOrderChange,
      );
    } else {
      AddFoodItemCommunityOrders(
        props.idx,
        props.userOrder,
        props.user,
        props.imported,
        props.order,
        state,
        props.userInfo,
        props.orderLog,
        props.updateOrderLog,
        props.orderIndex,
        props.location,
        props.updatePaymentHistory,
        communityOrderChunks,
        setCommunityOrderChunks,
      );
    }

    handleClose();
  };

  return (
    <SetVariablesContext.Provider
      value={{
        displayedFoodItemsToSelect,
        farmersAvailable,
        foodItemsToSelect,
        setDisplayedFoodItemsToSelect,
        open,
      }}
    >
      <FoodItemInformationDialog
        buttonStyle={1}
        callSaveFunction={handleSave}
        userInfo={props.userInfo}
        showAutofill={true}
        loading={loading}
        setParentOpen={setOpen}
        addToDistributionLocation={addToDistributionLocation}
        {...props}
      />
    </SetVariablesContext.Provider>
  );
}
