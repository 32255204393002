// This is to add a new pantry foodItem to a distribution location's order.  These
// are items that have been ordered for the week but come from on site storage and
// are not brought in from the farm.
import firebase from "./../Firebase.js";

export default function AddPantryFoodItem(
  pantryFoodItem,
  order,
  orderLog,
  updateOrderLog,
  orderIndex,
) {
  const database = firebase.firestore();
  const batch = database.batch();
  // Create a reference to the distribution location's order for the selected order
  const docRef = database
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("Orders")
    .doc(order.orderDate);

  // This is used to store the food items
  let pantryFoodItemsTemp = [];

  // If the pantryItems already exist for this order then initialise those pantryFoodItems
  // to the current ones.
  if (order.pantryFoodItems) {
    pantryFoodItemsTemp = [...order.pantryFoodItems];
  }

  // This is to be updated so that the array that holds all the orders from myAccount
  // is correct with the changes.
  const orderLogTemp = [...orderLog];

  // Add the foodItem to the foodList
  pantryFoodItemsTemp.push(pantryFoodItem);

  // Update the database
  batch.update(docRef, {
    pantryFoodItems: [...pantryFoodItemsTemp],
  });

  // Update the orderLog's pantryFoodItems.
  orderLogTemp[orderIndex].pantryFoodItems = [...pantryFoodItemsTemp];

  // Commit changes to the database.
  batch.commit();

  // Updates the orderLog to make sure the screen shows the correct information
  updateOrderLog([...orderLogTemp]);

  console.log("Successfully wrote to database!");
}
