import React from "react";
import firebase from "./../Firebase.js";
import AddCategoryToDistributionLocations from "../../functions/AddCategoryToDistributionLocations.js";
import HandleRefresh from "./../../pages/MarketplacePage/Functions/HandleRefresh.js";
import FoodItemInformationDialog from "./../dialogs/Components/FoodItemInformationDialog.jsx";

export default function MarketplaceAddDialog(props) {
  const handleSave = (state, handleClose) => {
    const database = firebase.firestore();
    const batch = database.batch();
    // the state of this dialog is all the fields of the foodItem.
    const item = state.item;
    const description = state.description;
    const category = state.category;
    const farmLimit = state.farmLimit;
    const guaranteedMaxQuantity = state.guaranteedMaxQuantity;
    const farmName = state.farmName;

    const documentName = (item + description + farmName).replace(/\s+/g, "");
    AddCategoryToDistributionLocations(
      props.farmCategories,
      farmName,
      category,
    );

    const docRef = database.collection("FoodItems").doc(documentName);

    const newItem = { ...state };
    newItem.userId = firebase.auth().currentUser.uid;
    newItem.adminVisible = false;
    newItem.distributionLocationVisible = [];
    newItem.userType = props.userInfo.userType;

    // Checks to see if the farmLimit was entered.  If it was then add it to the
    // item to be saved into the database.
    if (farmLimit) {
      newItem.farmLimit = parseFloat(farmLimit);
    }
    // Check if the guaranteedMaxQuantity field exists if it does then
    // we want to add it to the item.
    if (guaranteedMaxQuantity) {
      newItem.guaranteedMaxQuantity = parseInt(guaranteedMaxQuantity);
    }
    batch.set(docRef, newItem);
    batch.commit();
    console.log("Successfully wrote to database!");
    handleClose();
    HandleRefresh(props.foodItems, props.setFoodItems, newItem, true);
  };

  return (
    <div>
      <FoodItemInformationDialog
        buttonStyle={2}
        callSaveFunction={handleSave}
        userInfo={props.userInfo}
        farms={props.farms}
      />
    </div>
  );
}
