// This is used to display the row on the basket page of a foodItem that was added
// by the user.
import React from "react";
import BasketIncrCounter from "../BasketIncrCounter.jsx";
import { Button } from "@material-ui/core";

// Creates each basket item row of the basket information displayed
export default function BasketFoodRow({
  row,
  checked,
  idx,
  handleRefresh,
  handleQuantityChange,
  userType,
}) {
  // The default image to display if this element has no image.
  let displayImage =
    "https://lh3.googleusercontent.com/Issibzc2aRSgeCb-ZEvy2FdRek0t_76tclQJR7sZ64UFX2BHJPi3bXlBGpC_CAMDlwvb9jx_UPanf5jf0lKhXqd2qUqs1JvrEnRohV8Rv0-Qc84mgzMPe4FRYRvAuoO-5oj5gG1cps6KS7pTPh-hVJHjUCFmoUJt_mP1XYf-7ToSp16li0EoxMrTf6XOcRXq4t1D4QNOsy0HFR67bPCz_aFLzAnGGRBn8fYeg6ZDBrEA_bmJT574DSGQ9KVPE8xbJIGsbAXg7gkak6w-kUwR9Zh43UoE_R7fMeH7N3Ck2zGUPz8fk2gqHT_PlzQ_8gSDlmvNj8XJexH_PofD_fvBF3qfJW5pDO124JmKKlgdbNzXLM0VUpkoUH95mOVkblwpQzG4LJ-GXctSk-ycY1ikWj_xC_R1uyz8drfdXkqtIC0lyWhNwtVzqlZmHjTsOBVsiyaeqvtmFDZetEvuXm8vYTI4bIAa89eXH1Zt81g9XKVfubR8_eQH-3DNAC3Jam84_ro_38rZwxqI--HcJzgj1zcjV6FLCyFeegovwtSxC3VX70XZFUH4Qlv7bBfRK4CCs6lg-AlNgIYmnAr2Jjik9oL2y567f4zK_VROoVH7994c2QJ4S0QhX3-KntKrgIaxJxdrDk-6dayyMprKCI-ZXfF7uT3qk1oDR1HVBkW1G_Y60jyZvWL-dtoS-m1rzr5DrRTtYpxcdKVIMWuHbbGAGlQ=s512-no?authuser=2";

  // If the foodItem does have an image to display it will display it instead.
  if (!(row.displayImage === undefined || row.displayImage === "")) {
    displayImage = row.displayImage;
  }

  // Removes the basketRow Item and sets the quantity to 0 by updating the database
  // It will also call handleRefresh and remove the farm from the list of providing
  // farms
  const handleRemove = () => {
    const itemToBeRemoved = (row.item + row.description + row.farmName).replace(
      /\s+/g,
      "",
    );

    handleRefresh(row.farmName, itemToBeRemoved);

    console.log("Removed from Basket", itemToBeRemoved);
  };

  const incrementItem = () => {
    // If the user has ordered more then the limit then we set this to true and
    // prevent them from ordering anymore.
    let farmLimited = false;
    // If the user is an inidividual then we use the quantity and compare it to the
    // the farmLimit which is denominated in individualQuantity.
    if (userType === "individual") {
      // If the difference is less then 1 then we know that the item has gone over
      // the amount available and we have to limit the user from ordering more.
      if (row.farmLimit - row.quantity < 1) {
        farmLimited = true;
      }
    }
    // If the user is a distribution location then we need to convert the farmLimit
    // to the distribution location's amounts and compare if the user can add more
    // of the item to their order.
    else {
      if (
        (row.farmLimit * row.individualQuantity) / row.distributionQuantity -
          row.quantity <
        1
      ) {
        farmLimited = true;
      }
    }

    // make sure that the user hasn't reached the limit if there is one of leftover items.
    // If there is not limit then the user is fine.  If the limit exists then we don't care
    // about the farmLimit since we're taking from the pantry not ordering from the farmer.
    if (
      ((row.limit === undefined || row.limit === "") && !farmLimited) ||
      row.limit - row.quantity >= 1
    ) {
      handleQuantityChange(
        true,
        (row.item + row.description + row.farmName).replace(/\s+/g, ""),
      );
    }
  };

  const decreaseItem = () => {
    if (row.quantity === 1) {
      handleRemove();
    } else {
      handleQuantityChange(
        false,
        (row.item + row.description + row.farmName).replace(/\s+/g, ""),
      );
    }
  };

  // The price of the item for the distribution location price.
  let price = parseFloat(row.price).toFixed(2);
  // The price of the item for the individual.
  let individualPrice = parseFloat(
    price / (row.distributionQuantity / row.individualQuantity),
  );
  // The description of the item that will be used to tell the user the quantity they're buying.
  let description = row.description;

  // If the user is an individual user than we have to set the description to the
  // individual description.
  if (userType === "individual") {
    description = row.individualDescription;
  }

  // if suggestedPrice is true then we want to set the price to 0
  if (row.suggestedPrice) {
    if (userType === "individual") {
      // First add to the description the suggested Donation.
      description = description.concat(
        " - Suggested Donation of $",
        individualPrice.toFixed(2),
      );
    } else {
      // First add to the description the suggested Donation.
      description = description.concat(" - Suggested Donation of $", price);
    }

    // Then reset the prices to 0.
    price = "0.00";
    individualPrice = 0.0;
  }
  // If the package fee is more than nothing then add it to the total price.
  if (row.packageFee > 0) {
    individualPrice += parseFloat(row.packageFee);
  }
  // Make sure it displayed in dollar format.
  individualPrice = individualPrice.toFixed(2);
  // Says that there is a limit on this item or not.  Default to no limit
  let limited = false;
  // The quantity of items left in the pantry.
  let limitQuantity = 0;
  // Says that there is a limit set by the farmer on this item or not.  Default to no limit
  let farmLimited = false;
  // The quantity of items that the farmer has.
  let farmLimitQuantity = 0;
  // If the item has a limit then set it to true.  If there is no limit then check
  // if there is a limit set by the farmer.  If the farmLimit exists or if it is 0 then we know that
  // the item is limited.
  if (!!row.limit || row.limit === 0) {
    limited = true;
    // If the user is an individual then we set the limit based off the individual
    // quantities.
    if (userType === "individual") {
      limitQuantity = Math.floor(row.limit - row.quantity);
    }
    // Otherwise we set to false because the non individual users don't have pantry
    // limits on items.
    else {
      limited = false;
    }
  }
  // If there are no pantry limits check to see if there is a farm limit or if it
  // is 0.
  else if (!!row.farmLimit || row.farmLimit === 0) {
    farmLimited = true;
    // If the user is an individual then we set the limit based off the individual
    // quantities.
    if (userType === "individual") {
      farmLimitQuantity = Math.floor(row.farmLimit - row.quantity);
    }
    // If the user is an distributionLocation then we set the limit based off the
    // distributionLocation quantities.
    else {
      farmLimitQuantity =
        (row.farmLimit * row.individualQuantity) / row.distributionQuantity -
        row.quantity;
    }
  }

  return (
    <div id={row.item + "-" + idx} className="Basket-Row">
      <div style={{ display: "flex" }}>
        <div className="Image-Limited-Box">
          {limited && (
            <p className="Basket-Limited-Remaining">{limitQuantity} left</p>
          )}
          {farmLimited && (
            <p className="Basket-Limited-Remaining">{farmLimitQuantity} left</p>
          )}
          <img
            style={{
              width: "150px",
              height: "150px",
              objectFit: "cover",
              margin: "5px",
            }}
            alt="Food"
            src={displayImage}
            referrerPolicy="no-referrer"
          />
        </div>
        <div style={{ marginLeft: "10px" }}>
          <h5 className="Basket-Item-Name-Font">{row.item}</h5>
          <h5 className="Basket-Farm-Name-Font">{row.farmName}</h5>
          <Button onClick={handleRemove} color="secondary">
            REMOVE
          </Button>
        </div>
      </div>

      <div className="Counter-Price-Description" style={{ textAlign: "end" }}>
        <BasketIncrCounter
          quantity={row.quantity}
          incrementItem={incrementItem}
          decreaseItem={decreaseItem}
          hideButtons={false}
        />
        {userType === "individual" ? (
          <div>
            <h3 className="Price-And-Description">
              ${individualPrice}
              {row.packageFee > 0 && <>*</>}/ {description}
            </h3>
            {row.packageFee > 0 && (
              <p className="Item-Package-Fee">
                *includes package fee ${parseFloat(row.packageFee).toFixed(2)}
                /per item
              </p>
            )}
            <h3 className="Total-Price">
              Total : (${(individualPrice * row.quantity).toFixed(2)})
            </h3>
          </div>
        ) : (
          <div>
            <h3 className="Price-And-Description">
              ${price}
              {row.packageFee > 0 && <>*</>} / {description}
            </h3>
            {row.packageFee > 0 && (
              <p className="Item-Package-Fee">
                * plus package fee ${parseFloat(row.packageFee).toFixed(2)}/per
                item
              </p>
            )}
            <h3 className="Total-Price">
              Total : (${(price * row.quantity).toFixed(2)})
            </h3>
          </div>
        )}
      </div>
    </div>
  );
}
