// This is to update an individual user's donation or subsidy amounts and update
// whether they are donating or using a subsidy from the myAccount Page
import firebase from "./../Firebase.js";
import IndividualPriceChangesPaymentHistory from "./../../pages/MyAccountPage/Functions/PriceHistoryChanges/IndividualPriceChangesPaymentHistory.js";
import UpdateCommunityOrdersDatabase from "./../../pages/BasketPage/Functions/OrderUpdates/UpdateCommunityOrdersDatabase.js";

export default function UpdateCustomerContribution(
  props,
  userOrder,
  updatePaymentHistory,
  communityOrderChunks,
  setCommunityOrderChunks,
) {
  const database = firebase.firestore();
  const batch = database.batch();
  // Includes the user's entire order
  const order = props.order;
  // This is the orderLog of all the orders made by the Distribution Location
  const orderLog = props.orderLog;
  const user = props.user;
  const imported = props.imported;
  const userInfo = props.userInfo;
  const updateOrderLog = props.updateOrderLog;
  const orderIndex = props.orderIndex;

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // Finds when then user ordered to help find the individual's order in the database
  const userOrderDate = userOrder.orderDate;

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userOrderDate);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...orderLog];

  if (imported) {
    // If the imported Orders are being modified change this to be correct
    // userDataRef = ["importedOrder", props.location, "communityOrders"].join(
    //   ".",
    // );

    // As this an imported order it needs to update the correct collection in the database
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(props.location)
      .collection("CommunityOrders")
      .doc(order.importedOrder[props.location].date);
  } else {
    // Update the current distribution locations orders in the DistributionLocations
    // collection
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(userInfo.organisationName)
      .collection("CommunityOrders")
      .doc(order.selectedDate);
  }

  // Remove the Dicts as they were added for rendering purposes and should not
  // be saved to the database
  delete userOrder.foodDict;
  delete userOrder.farmDict;

  // Users -> userId -> Orders -> date -> communityOrders/importedOrders ->
  // FirstName-LastName-userId-date
  UpdateCommunityOrdersDatabase(
    "SingleUserOverwrite",
    batch,
    communityOrderChunks,
    setCommunityOrderChunks,
    null,
    order.orderDate,
    user,
    userOrder,
  );

  // Updates the DistributionLocations -> nameofDistributionLocation ->
  // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
  batch.update(communityOrdersDocRef, {
    [user]: { ...userOrder },
  });

  // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
  // FirstName-LastName-userId-date
  batch.update(userDocRef, {
    customerContribution: userOrder.customerContribution,
    donationSubsidy: userOrder.donationSubsidy,
  });

  // The user's original order before the changes.
  let originalUserOrder = {};

  // This is to make sure the page rerenders these items correctly
  if (imported) {
    // Set the user order before changes.
    originalUserOrder = {
      ...orderLogTemp[orderIndex].importedOrder[props.location].communityOrders[
        user
      ],
    };
    orderLogTemp[orderIndex].importedOrder[props.location].communityOrders[
      user
    ] = { ...userOrder };
  } else {
    // Set the user order before changes.
    originalUserOrder = { ...orderLogTemp[orderIndex].communityOrders[user] };
    orderLogTemp[orderIndex].communityOrders[user] = { ...userOrder };
  }

  // This is just the value of the subsidy/donation.  It will be negative if it
  // is a subsidy or positive if it is a donation.
  let originalOrderCost = 0;
  // This is just the value of the subsidy/donation after the change.  It will be negative if it
  // is a subsidy or positive if it is a donation.
  let updatedOrderCost = 0;

  // Add or subtract the value of the original amount to get the value.
  if (originalUserOrder.customerContribution === "donation") {
    originalOrderCost += originalUserOrder.donationSubsidy;
  } else {
    originalOrderCost -= originalUserOrder.donationSubsidy;
  }

  // Add or subtract the value of the updated amount to get the updated value.
  if (userOrder.customerContribution === "donation") {
    updatedOrderCost += userOrder.donationSubsidy;
  } else {
    updatedOrderCost -= userOrder.donationSubsidy;
  }

  // Since this could be called from the Basket Page we don't want to update the
  // payment history.
  if (updatePaymentHistory) {
    // Update the payment history.
    IndividualPriceChangesPaymentHistory(
      order.orderDate,
      originalOrderCost,
      updatedOrderCost,
      null,
      null,
      null,
      imported,
      props.location,
      user,
      "donationSubsidyChange",
      updatePaymentHistory,
    );
  }

  batch.commit();
  // Updates the orderLog to make sure the screen shows the correct information
  updateOrderLog([...orderLogTemp]);

  console.log("Successfully wrote to database!");
}
