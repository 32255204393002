// This is to add a new foodItem to a community order either imported or not from
// a request on the myAccount Page
import firebase from "./../Firebase.js";
import AddRemoveItemPaymentHistory from "./../../pages/MyAccountPage/Functions/PriceHistoryChanges/AddRemoveItemPaymentHistory.js";
import VolunteerFeeAdjustment from "./VolunteerFeeAdjustment.js";
import UpdateCommunityOrdersDatabase from "./../../pages/BasketPage/Functions/OrderUpdates/UpdateCommunityOrdersDatabase.js";

// Index is the index of the item in the dictionary of orders,
// userOrder contains: customerContribution: "donation", farmDict: {}, farmerList: [{…}, {…}], foodDict: {}, foodList: [{…}, {…}], orderDate: 202012101607...
// user contains: firstName-lastName-userId-orderDate
// imported contains : boolean
// order contains : communityOrders: {}, contactMethods: {}, farmerList: [{…}], foodList: [{…}], importedDistributionDate: null, importedDistributionLocation: null, importedOrder: {}, orderDate: "202012101607633156316", owner: "CNjgN4YCGkNlpaBOXukfHz2neLi2", selectedDate:"12-20-2020"
// state contains : description: "1 Litre", distributionQuantity: "1", farmName: "Local Harvest", individualDescription: "1 Litre", individualQuantity: "1", item: "Milk", open: true, price: "4.99", quantity: "6", selectedDate: Thu Dec 10 2020 13:04:57 GMT-0800 (Pacific Standard Time) {}, farmName: "Local Harvest"
// userInfo contains: information about the current user
// orderLog contains: [array with each orderLoaded for the user]
// updateOrderLog contains: a function to update OrderLog
// orderIndex contains: the index of the current order in the orderLog array
export default function AddFoodItemCommunityOrders(
  idx,
  userOrder,
  user,
  imported,
  order,
  state,
  userInfo,
  orderLog,
  updateOrderLog,
  orderIndex,
  location,
  updatePaymentHistory,
  communityOrderChunks,
  setCommunityOrderChunks,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // Finds when then user ordered to help find the individual's order in the database
  const userOrderDate = userOrder.orderDate;

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userOrderDate);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...orderLog];

  // This contains an array of foodItems that the user ordered
  let foodListTemp = [];

  // This contains an array of farmers that the user ordered from
  let farmerListTemp = [];

  // If the distribution location user is modifiying an imported user then...
  if (imported) {
    // pull the current foodList of the user being modified
    foodListTemp = [
      ...order.importedOrder[location].communityOrders[user].foodList,
    ];

    // pull the current farmerList of the user being modified
    farmerListTemp = [
      ...order.importedOrder[location].communityOrders[user].farmerList,
    ];

    // If the imported Orders are being modified change this to be correct
    // userFoodList = ["importedOrder", location, "communityOrders"].join(".");

    // If the imported Orders are being modified change this to be correct
    // userFarmerList = ["importedOrder", location, "communityOrders"].join(".");

    // As this an imported order it needs to update the correct collection in the database
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(location)
      .collection("CommunityOrders")
      .doc(order.importedOrder[location].date);
  } else {
    // pull the current foodList of the user being modified
    foodListTemp = [...order.communityOrders[user].foodList];

    // pull the current farmerList of the user being modified
    farmerListTemp = [...order.communityOrders[user].farmerList];

    // Update the current distribution locations orders in the DistributionLocations
    // collection
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(userInfo.organisationName)
      .collection("CommunityOrders")
      .doc(order.selectedDate);
  }

  const foodItem = { ...state };
  foodItem.userId = firebase.auth().currentUser.uid;
  // This is hardcoded to match deprecated code
  foodItem.userType = "admin";

  // If the state has a limit then add that to the foodItem.
  if (state.limit !== "") {
    foodItem.limit = state.limit;
  }

  // Push the foodItem to the list
  foodListTemp.push(foodItem);

  // We use this to calculate the new cash and credit portions of the volunteer fee after the
  // adjustments. Since we are adding an item we don't actually use the credits portion since
  // adding an item will always add to the cash portion.
  // eslint-disable-next-line no-unused-vars
  const [userCashPortion, userCreditPortion, volunteerFeeAdded] =
    VolunteerFeeAdjustment(
      userOrder,
      userId,
      userInfo,
      foodItem,
      database,
      batch,
      false,
    );

  // Find if the farm exists already and if so the index
  const farmIndex = farmerListTemp.findIndex((farm) => {
    return farm.farmName === state.farmName;
  });

  // If the farm exists
  if (farmIndex !== -1) {
    // Update the userOrder.
    const userOrderTemp = JSON.parse(
      JSON.stringify(order.communityOrders[user]),
    );
    userOrderTemp.foodList = [...foodListTemp];
    userOrderTemp.cashPortion = userCashPortion.toFixed(2);
    // Users -> userId -> Orders -> date -> communityOrders/importedOrders ->
    // FirstName-LastName-userId-date
    UpdateCommunityOrdersDatabase(
      "SingleUserOverwrite",
      batch,
      communityOrderChunks,
      setCommunityOrderChunks,
      null,
      order.orderDate,
      user,
      userOrderTemp,
    );

    // Updates the DistributionLocations -> nameofDistributionLocation ->
    // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
    batch.update(communityOrdersDocRef, {
      [user.concat(".foodList")]: [...foodListTemp],
      [user.concat(".cashPortion")]: userCashPortion.toFixed(2),
    });

    // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
    // FirstName-LastName-userId-date
    batch.update(userDocRef, {
      foodList: [...foodListTemp],
      cashPortion: userCashPortion.toFixed(2),
    });

    // This is to make sure the page rerenders these items correctly
    if (imported) {
      orderLogTemp[orderIndex].importedOrder[location].communityOrders[
        user
      ].foodList = [...foodListTemp];
      orderLogTemp[orderIndex].importedOrder[location].communityOrders[
        user
      ].cashPortion = userCashPortion.toFixed(2);
    } else {
      orderLogTemp[orderIndex].communityOrders[user].foodList = [
        ...foodListTemp,
      ];
      orderLogTemp[orderIndex].communityOrders[user].cashPortion =
        userCashPortion.toFixed(2);
    }
    batch.commit();

    // Update the payment history with the new item.
    AddRemoveItemPaymentHistory(
      order.orderDate,
      imported,
      foodItem,
      user,
      location,
      true,
      updatePaymentHistory,
      volunteerFeeAdded,
    );
    // Updates the orderLog to make sure the screen shows the correct information
    updateOrderLog([...orderLogTemp]);
  }
  // If the farm doesn't exist
  else {
    // Find information about the farm from the database to add to the user's order
    const farmDocRef = database.collection("Farmers").doc(state.farmName);
    farmDocRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          farmerListTemp.push(doc.data());
          // Update the userOrder to updated amounts.
          const userOrderTemp = JSON.parse(
            JSON.stringify(order.communityOrders[user]),
          );
          userOrderTemp.foodList = [...foodListTemp];
          userOrderTemp.farmerList = [...farmerListTemp];
          userOrderTemp.cashPortion = userCashPortion.toFixed(2);
          // Users -> userId -> Orders -> date -> communityOrders/importedOrders ->
          // FirstName-LastName-userId-date
          UpdateCommunityOrdersDatabase(
            "SingleUserOverwrite",
            batch,
            communityOrderChunks,
            setCommunityOrderChunks,
            null,
            order.orderDate,
            user,
            userOrderTemp,
          );
          // Updates the DistributionLocations -> nameofDistributionLocation ->
          // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
          batch.update(communityOrdersDocRef, {
            [user.concat(".foodList")]: [...foodListTemp],
            [user.concat(".farmerList")]: [...farmerListTemp],
            [user.concat(".cashPortion")]: userCashPortion.toFixed(2),
          });

          // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
          // FirstName-LastName-userId-date
          batch.update(userDocRef, {
            foodList: [...foodListTemp],
            farmerList: [...farmerListTemp],
            cashPortion: userCashPortion.toFixed(2),
          });

          // This is to make sure the page rerenders these items correctly
          if (imported) {
            orderLogTemp[orderIndex].importedOrder[location].communityOrders[
              user
            ].foodList = [...foodListTemp];
            orderLogTemp[orderIndex].importedOrder[location].communityOrders[
              user
            ].farmerList = [...farmerListTemp];
            orderLogTemp[orderIndex].importedOrder[location].communityOrders[
              user
            ].cashPortion = userCashPortion.toFixed(2);
          } else {
            orderLogTemp[orderIndex].communityOrders[user].foodList = [
              ...foodListTemp,
            ];
            orderLogTemp[orderIndex].communityOrders[user].farmerList = [
              ...farmerListTemp,
            ];
            orderLogTemp[orderIndex].communityOrders[user].cashPortion =
              userCashPortion.toFixed(2);
          }
          batch.commit();

          // Update the payment history with the new item.
          AddRemoveItemPaymentHistory(
            order.orderDate,
            imported,
            foodItem,
            user,
            location,
            true,
            updatePaymentHistory,
            volunteerFeeAdded,
          );
          // Updates the orderLog to make sure the screen shows the correct information
          updateOrderLog([...orderLogTemp]);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }
}
