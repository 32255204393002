// This is called by the Food Item Information dialog and shows the different tags
// available.
import React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getIconByKey } from "../../../../images/Icons"; // Import the function to get icons
import { blue, lightBlue } from "@mui/material/colors"; // Colors for tags

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function FoodItemTags({ tagList, setTagList }) {
  const availableTags = [
    { key: 0, label: "Fridge", color: blue[500], hoverColor: blue[700] },
    {
      key: 1,
      label: "Frozen",
      color: lightBlue[200],
      hoverColor: lightBlue[500],
    },
  ];
  const handleClick = (clickedTag) => {
    // Check if the tag is already in the tagList
    const tagExists = tagList.some((tag) => tag.key === clickedTag.key);

    if (tagExists) {
      // If the tag exists, remove it from the tagList
      const updatedTagList = tagList.filter(
        (tag) => tag.key !== clickedTag.key,
      );
      setTagList(updatedTagList);
    } else {
      // If the tag doesn't exist, add it to the tagList
      const updatedTagList = [...tagList, clickedTag];
      setTagList(updatedTagList);
    }
  };
  return (
    <div>
      {/* Label for the tag list */}
      <DialogTitle
        id="food-item-tags-title"
        style={{ padding: "16px 24px 0px" }}
      >
        {"Food Item Tags"}
      </DialogTitle>

      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        {availableTags.map((data) => {
          // Check if the tag is currently in the tagList
          const isSelected = tagList.some((tag) => tag.key === data.key);
          const variant = isSelected ? "filled" : "outlined";
          const color = data.color; // Use color from state
          const hoverColor = data.hoverColor; // Define hover color from state

          return (
            <ListItem key={data.key}>
              <Chip
                variant={variant}
                label={data.label}
                onClick={() => handleClick(data)}
                icon={getIconByKey(data.key)} // Use the icon from the state
                sx={{
                  borderColor: color, // Color for outlined variant
                  color, // Text and icon color
                  ...(isSelected && {
                    backgroundColor: color,
                    color: "white",
                  }), // Filled variant
                  "&:hover": {
                    backgroundColor: isSelected ? hoverColor : "transparent", // Only change background if active
                    borderColor: hoverColor, // Border changes color on hover when not active
                    color: isSelected ? "white" : hoverColor, // Text color remains readable
                  },
                }}
              />
            </ListItem>
          );
        })}
      </Paper>
    </div>
  );
}
