export default async function LoadCommunityOrders(
  database,
  currentUser,
  loadLimit,
  setLastLoadedOrder,
  setOrderLog,
  setLoading,
  setCommunityOrderChunks,
  loadMore,
  orderLog,
  lastLoadedOrder,
) {
  try {
    // This is used to grab the data of foodItems from the database.  It only grabs
    // the foodItems that the user has in their marketplace.
    let dataTransfer = [];
    if (loadMore) {
      dataTransfer = [...orderLog];
    }

    // This is to stop showing the Load more once they've all been loaded
    let loadedDocs = loadLimit - 1;

    const communityOrderChunks = {}; // Store chunks for each order date

    let ordersCollection;

    // Conditional logic to handle loadMore flag
    if (loadMore && lastLoadedOrder) {
      // When loading more, continue after the last loaded order
      ordersCollection = await database
        .collection("Users")
        .doc(currentUser.uid)
        .collection("Orders")
        .orderBy("orderDate", "desc")
        .startAfter(lastLoadedOrder) // Load after last order
        .limit(loadLimit)
        .get();
    } else {
      ordersCollection = await database
        .collection("Users")
        .doc(currentUser.uid)
        .collection("Orders")
        .orderBy("orderDate", "desc")
        .limit(loadLimit)
        .get();
    }

    // Stores the last document loaded to the hook if the load limit was reached
    // then it will store a value if there weren't enough documents to reach
    // the limit then we won't have to load anymore and so it's set to null
    if (loadMore) {
      setLastLoadedOrder(
        ordersCollection.docs[ordersCollection.docs.length - 1],
      );
      loadedDocs = ordersCollection.docs.length - 1;
      // Checks if the number of loaded docs is less than the limit
      if (loadedDocs < loadLimit - 1) {
        setLastLoadedOrder(null);
      }
    } else {
      if (ordersCollection.docs.length === loadLimit) {
        setLastLoadedOrder(
          ordersCollection.docs[ordersCollection.docs.length - 1],
        );
      } else {
        setLastLoadedOrder(null);
      }
    }

    // Save the loaded results from the database into hooks.
    for (const docOrders of ordersCollection.docs) {
      let orderData = docOrders.data();
      const communityOrderChunkTemp = []; // Initialize per orderDate
      if (orderData.overFlowCount && orderData.overFlowCount > 0) {
        // The communityOrders for the orderDate need to be combined.
        let combinedCommunityOrders = { ...orderData.communityOrders };
        // We also want to add the communityOrders to their respective chunks.
        communityOrderChunkTemp.push({ ...orderData.communityOrders });
        // Load the overFlow docs
        for (let i = 1; i <= orderData.overFlowCount; i++) {
          const overFlowDocRef = database
            .collection("Users")
            .doc(`${orderData.owner}`)
            .collection("Orders")
            .doc(`${orderData.orderDate}`)
            .collection("OverFlow")
            .doc(`${orderData.orderDate}${i}`);

          const overFlowDocSnap = await overFlowDocRef.get();
          // If the overFlow Doc exists add it to the combinedCommunityOrders
          // and save it to the orderChunks list.
          if (overFlowDocSnap.exists) {
            const overFlowDocData = overFlowDocSnap.data();
            combinedCommunityOrders = {
              ...combinedCommunityOrders,
              ...overFlowDocData?.communityOrders,
            };
            communityOrderChunkTemp.push({
              ...overFlowDocData?.communityOrders,
            });
          }
        }
        orderData = { ...orderData, communityOrders: combinedCommunityOrders };
      }
      // Store chunks for this specific orderDate
      communityOrderChunks[orderData.orderDate] = communityOrderChunkTemp;

      // Add to the data transfer
      dataTransfer.push(orderData);
    }

    if (dataTransfer.length !== 0) {
      // Set all community order chunks for all order dates
      setCommunityOrderChunks(communityOrderChunks);
      setOrderLog(dataTransfer);
    }
    setLoading(true);
  } catch (error) {
    console.log("Error getting document:", error);
    setLoading(true);
  }
}
