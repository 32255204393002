// This is to show the user their options of the format to download
// the orders in.
import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DownloadOrders from "../../functions/DownloadOrders.js";
import PrintOutSelectionDialog from "./PrintOutSelectionDialog.jsx";

// This is the dialog box that opens when a user clicks on View Customer Receipts
function OrderDownloadSelectionBox({
  onClose,
  open,
  selectedDate,
  distributionLocation,
  downloadString,
  downloadGridString,
}) {
  const handleClose = () => {
    onClose(false);
  };

  // If the user clicks the Download button the function DownloadOrders is called
  // with the argument of the community orders in string format that will be used
  // to display the user receipts.  The format will depend on what the users chose.
  const handleDownload = (format, printList) => {
    // If the printList isn't null then we know we are printing
    // the public hub lists.  We want to print a separate sheet
    // per location.
    if (printList != null) {
      printList.forEach((pickupLocation) => {
        DownloadOrders(format[pickupLocation], selectedDate, pickupLocation);
      });
    } else {
      DownloadOrders(format, selectedDate, distributionLocation);
    }
    onClose(true);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        What Format Would You Like To Download?
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <PrintOutSelectionDialog
            downloadString={downloadString}
            handleDownload={handleDownload}
          />
          <Button
            onClick={() => handleDownload(downloadGridString)}
            style={{
              marginBottom: "10px",
              border: "1px solid #2196f3",
              borderRadius: "5px",
              color: "#2196f3",
              padding: "10px 20px",
            }}
          >
            GRID FORMAT USED FOR PACKAGING
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

OrderDownloadSelectionBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedDate: PropTypes.string.isRequired,
  distributionLocation: PropTypes.string.isRequired,
  downloadString: PropTypes.string.isRequired,
  downloadGridString: PropTypes.string.isRequired,
};

export default function OrderDownloadSelectionDialog({
  selectedDate,
  distributionLocation,
  downloadString,
  downloadGridString,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        DOWNLOAD
      </Button>

      <OrderDownloadSelectionBox
        open={open}
        onClose={handleClose}
        selectedDate={selectedDate}
        distributionLocation={distributionLocation}
        downloadString={downloadString}
        downloadGridString={downloadGridString}
      />
    </div>
  );
}
