// This is to add a new foodItem to a community order either imported or not from
// a request on the Basket Page
import firebase from "./../Firebase.js";
import VolunteerFeeAdjustment from "./VolunteerFeeAdjustment.js";

// Index is the index of the item in the dictionary of orders,
// userOrder contains: customerContribution: "donation", farmDict: {}, farmerList: [{…}, {…}], foodDict: {}, foodList: [{…}, {…}], orderDate: 202012101607...
// user contains: firstName-lastName-userId-orderDate
// imported contains : boolean
// order contains : communityOrders: {}, contactMethods: {}, farmerList: [{…}], foodList: [{…}], importedDistributionDate: null, importedDistributionLocation: null, importedOrder: {}, orderDate: "202012101607633156316", owner: "CNjgN4YCGkNlpaBOXukfHz2neLi2", selectedDate:"12-20-2020"
// state contains : description: "1 Litre", distributionQuantity: "1", farmName: "Local Harvest", individualDescription: "1 Litre", individualQuantity: "1", item: "Milk", open: true, price: "4.99", quantity: "6", selectedDate: Thu Dec 10 2020 13:04:57 GMT-0800 (Pacific Standard Time) {}, farmName: "Local Harvest"
// userInfo contains: information about the current user
export default function AddFoodItemCommunityOrdersBasket(
  idx,
  userOrder,
  user,
  imported,
  importedOrder,
  location,
  selectedDate,
  state,
  userInfo,
  handleCommunityOrderChange,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // Finds when then user ordered to help find the individual's order in the database
  const userOrderDate = userOrder.orderDate;

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userOrderDate);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // This contains an array of foodItems that the user ordered
  const foodListTemp = userOrder.foodList;

  // This contains an array of farmers that the user ordered from
  const farmerListTemp = userOrder.farmerList;

  if (imported) {
    // As this an imported order it needs to update the correct collection in the database
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(location)
      .collection("CommunityOrders")
      .doc(importedOrder[location].date);
  } else {
    // Update the current distribution locations orders in the DistributionLocations collection
    communityOrdersDocRef = database
      .collection("DistributionLocations")
      .doc(userInfo.organisationName)
      .collection("CommunityOrders")
      .doc(selectedDate);
  }

  const foodItem = { ...state };
  foodItem.userId = firebase.auth().currentUser.uid;
  // This is hardcoded to match deprecated code
  foodItem.userType = "admin";

  // Push the foodItem to the list
  foodListTemp.push(foodItem);

  // We use this to calculate the new cash and credit portions of the volunteer fee after the
  // adjustments. Since we are adding an item we don't actually use the credits portion since
  // adding an item will always add to the cash portion.
  // eslint-disable-next-line no-unused-vars
  const [userCashPortion, userCreditPortion, volunteerFeeChange] =
    VolunteerFeeAdjustment(
      userOrder,
      userId[2],
      userInfo,
      foodItem,
      database,
      batch,
      false,
    );

  // Find if the farm exists already and if so the index
  const farmIndex = farmerListTemp.findIndex((farm) => {
    return farm.farmName === state.farmName;
  });

  // If the farm exists
  if (farmIndex !== -1) {
    // Updates the DistributionLocations -> nameofDistributionLocation ->
    // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
    batch.update(communityOrdersDocRef, {
      [user.concat(".foodList")]: [...foodListTemp],
      [user.concat(".cashPortion")]: userCashPortion.toFixed(2),
    });

    // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
    // FirstName-LastName-userId-date
    batch.update(userDocRef, {
      foodList: [...foodListTemp],
      cashPortion: userCashPortion.toFixed(2),
    });
    batch.commit();

    // Updates the user order so that we rerender the correct values.
    const userOrderTemp = { ...userOrder };
    userOrderTemp.foodList = [...foodListTemp];
    userOrderTemp.cashPortion = userCashPortion.toFixed(2);
    // Sends updated values to rerender the screen with the correct information
    handleCommunityOrderChange(
      imported,
      [...foodListTemp],
      [...farmerListTemp],
      user,
      null,
      null,
      location,
      null,
      userOrderTemp,
    );
  }
  // If the farm doesn't exist
  else {
    const farmDocRef = database.collection("Farmers").doc(state.farmName);
    farmDocRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          farmerListTemp.push(doc.data());

          // Updates the DistributionLocations -> nameofDistributionLocation ->
          // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
          batch.update(communityOrdersDocRef, {
            [user.concat(".foodList")]: [...foodListTemp],
            [user.concat(".farmerList")]: [...farmerListTemp],
            [user.concat(".cashPortion")]: userCashPortion.toFixed(2),
          });

          // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
          // FirstName-LastName-userId-date
          batch.update(userDocRef, {
            foodList: [...foodListTemp],
            farmerList: [...farmerListTemp],
            cashPortion: userCashPortion.toFixed(2),
          });
          batch.commit();
          // Updates the user order so that we rerender the correct values.
          const userOrderTemp = { ...userOrder };
          userOrderTemp.foodList = [...foodListTemp];
          userOrderTemp.farmerList = [...farmerListTemp];
          userOrderTemp.cashPortion = userCashPortion.toFixed(2);
          // Sends updated values to rerender the screen with the correct information
          handleCommunityOrderChange(
            imported,
            [...foodListTemp],
            [...farmerListTemp],
            user,
            null,
            null,
            location,
            null,
            userOrderTemp,
          );
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }
}
