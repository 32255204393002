// This is the button that the distribution location user can push
// to confirm they want to add the user to the order.  It will add the user
// to the "CHECK for UPDATES" button.
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CreateCommunityMemberOrder from "../functions/CreateCommunityMemberOrder.js";

export default function AddCommunityOrderButton({
  userInfo,
  distributionDate,
}) {
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleClick = () => {
    setButtonClicked(true);
    CreateCommunityMemberOrder(userInfo, distributionDate);
  };

  return (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      {buttonClicked ? (
        <Button disabled style={{ fontWeight: "bold" }}>
          ADDED
        </Button>
      ) : (
        <Button
          onClick={handleClick}
          style={{ color: "green", fontWeight: "bold" }} // Change font color and make it bold
        >
          ADD
        </Button>
      )}
    </div>
  );
}
