import React from "react";
import useFirestore from "./useFirestore";
import { useIsMounted } from "usehooks-ts";

type Order = {
  selectedDate: string;
  importedBy?: string;
  owner: string;
  communityOrders?: { [key: string]: any };
  overFlowCount?: number;
  orderDate?: string;
};

/**
 * useCommunityOrder gets an order document matching the `selectedDate`.
 *
 * If multiple distribution locations return an Order, the first Order WITHOUT an `importedBy` field will be returned.
 * @param _selectedDate
 * @returns Order
 */
export default function useCommunityOrder(_selectedDate: string) {
  const mounted = useIsMounted();
  const database = useFirestore();
  const [order, setOrder] = React.useState<Order | undefined>();
  const [initialized, setInitialized] = React.useState(false);

  const fetchOrder = React.useCallback(async (date: string) => {
    const ref = database
      .collectionGroup("Orders")
      .where("selectedDate", "==", date);

    try {
      const snap = await ref.get();
      if (!mounted()) return;

      // set the owner to be the parent document id
      // orders before 2021-06-24 did not have the owner field
      const orders = snap.docs.map((v) => ({
        owner: v.ref.parent.parent?.id,
        ...v.data(),
      })) as Order[];
      let found = orders.find((o) => o.importedBy == null);
      if (found) {
        if (found.overFlowCount && found.overFlowCount > 0) {
          let combinedCommunityOrders = { ...found.communityOrders };
          for (let i = 1; i <= found.overFlowCount; i++) {
            const overFlowDocRef = database
              .collection("Users")
              .doc(`${found.owner}`)
              .collection("Orders")
              .doc(`${found.orderDate}`)
              .collection("OverFlow")
              .doc(`${found.orderDate}${i}`);

            const overFlowDocSnap = await overFlowDocRef.get();
            if (overFlowDocSnap.exists) {
              const overFlowDocData = overFlowDocSnap.data();
              combinedCommunityOrders = {
                ...combinedCommunityOrders,
                ...overFlowDocData?.communityOrders,
              };
            }
          }
          found = { ...found, communityOrders: combinedCommunityOrders };
        }
      }
      setOrder(found);
    } catch (e) {
      console.error(e);
      throw e;
    }

    if (!mounted()) return;
    setInitialized(true);
  }, []);

  React.useEffect(() => {
    fetchOrder(_selectedDate);
  }, [_selectedDate]);

  return { order, initialized };
}
