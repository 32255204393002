// A sorting filter that will sort ALPHABETICALLY, sort alphabetically by farm name,
// or by the order in which it was ordered originally.  If the user wants the order
// to be alphatical by item name and farm names then they'll have to run this sort twice.
import React from "react";
import { ReorderFoodItems } from "../Functions/ReorderFoodItems.js";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CategoryIcon from "@mui/icons-material/Category";
import Button from "@material-ui/core/Button";

export default function SortFilter({
  foodList,
  setFoodList,
  originalFoodList,
  pantryFoodItems,
  setPantryFoodItems,
  originalPantryFoodItems,
  noSortByOrdered,
}) {
  return (
    <div className="Order-Sort-Options">
      <h6 style={{ marginRight: "5px" }}>Sort: </h6>
      <Button
        variant="outlined"
        startIcon={<SortByAlphaIcon />}
        onClick={() =>
          ReorderFoodItems(
            foodList,
            setFoodList,
            originalFoodList,
            pantryFoodItems,
            setPantryFoodItems,
            originalPantryFoodItems,
            true,
            false,
            false,
            false,
          )
        }
      >
        ALPHABETICALLY
      </Button>
      <Button
        variant="outlined"
        startIcon={<AgricultureIcon />}
        onClick={() =>
          ReorderFoodItems(
            foodList,
            setFoodList,
            originalFoodList,
            pantryFoodItems,
            setPantryFoodItems,
            originalPantryFoodItems,
            false,
            true,
            false,
            false,
          )
        }
      >
        BY FARM
      </Button>
      {!noSortByOrdered && (
        <Button
          variant="outlined"
          startIcon={<ReceiptLongIcon />}
          onClick={() =>
            ReorderFoodItems(
              foodList,
              setFoodList,
              originalFoodList,
              pantryFoodItems,
              setPantryFoodItems,
              originalPantryFoodItems,
              false,
              false,
              true,
              false,
            )
          }
        >
          BY ORDERED
        </Button>
      )}
      <Button
        variant="outlined"
        startIcon={<CategoryIcon />}
        onClick={() =>
          ReorderFoodItems(
            foodList,
            setFoodList,
            originalFoodList,
            pantryFoodItems,
            setPantryFoodItems,
            originalPantryFoodItems,
            false,
            false,
            false,
            true,
          )
        }
      >
        BY CATEGORY
      </Button>
    </div>
  );
}
