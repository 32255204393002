import React from "react";
import ModifyItemInPantry from "./../../Pantry/Functions/ModifyItemInPantry.js";
import FoodItemInformationDialog from "./../../dialogs/Components/FoodItemInformationDialog.jsx";

export default function PantryEditDialog(props) {
  const handleSave = (state, handleClose) => {
    // This will update the item in the pantry and updates the datbase.
    ModifyItemInPantry(
      props.leftovers,
      props.setLeftovers,
      props.userInfo,
      state,
      props.foodItem,
      props.index,
    );
    handleClose();
  };
  return (
    <div>
      <FoodItemInformationDialog
        buttonStyle={0}
        callSaveFunction={handleSave}
        userInfo={props.userInfo}
        limitRequired={true}
        foodItem={props.foodItem}
      />
    </div>
  );
}
