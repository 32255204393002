// This is used to display the row on the myaccount page of a foodItem that was taken
// from the pantry items.
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import PantryFoodItemPriceChangeDialog from "../Dialogs/PantryFoodItemPriceChangeDialog.jsx";
import EditDistributionLocationItemForm from "./EditDistributionLocationItemForm.jsx";
import { getIconByKey } from "../../../images/Icons"; // Import the function to get icons

export default function PantryMyAccountFoodRow({
  idx,
  food,
  editing,
  multipleSelection,
  userInfo,
  order,
  orderLog,
  updateOrderLog,
  orderIndex,
  handleSelectionChange,
}) {
  // The individual description of the food item for the user to know how
  // much they are purchasing.
  let individualDescription = food.individualDescription;
  // Set the price of the individal item
  let individualPrice = parseFloat(
    food.price / (food.distributionQuantity / food.individualQuantity),
  );

  // if suggestedPrice is true then we want to set the price to 0
  if (food.suggestedPrice) {
    // First add to the description the suggested Donation.
    individualDescription = individualDescription.concat(
      " - Suggested Donation of $",
      individualPrice,
    );
    // Then reset the prices to 0.
    individualPrice = 0.0;
  }

  return (
    <div key={idx} className="Editing-Remove-Food-Item">
      <div key={food + idx} className="Pantry-Food-Item">
        <div className="Food-Item-Desc-Quantity">
          <div className="Food-Item-Tags-And-Name">
            <h5 className="Food-Item-Child Food-Item-Header">
              {food.item}, {individualDescription}
            </h5>
            {/* Check if food.tagList exists and has items */}
            {food.tagList && food.tagList.length > 0 && (
              <div style={{ display: "inline-flex", marginLeft: "8px" }}>
                {food.tagList.map((tag) => (
                  <span key={tag.key} style={{ marginRight: "4px" }}>
                    {getIconByKey(tag.key)} {/* Render the icon for each tag */}
                  </span>
                ))}
              </div>
            )}
          </div>
          <h5 className="Food-Item-Child Food-Item-Header">x{food.quantity}</h5>
        </div>
        <div className="Order-Bottom-Row">
          <p className="Food-Item-Child">{food.farmName}</p>
          <span>
            <span className="Editing-Price-Change">
              {editing && !multipleSelection && (
                <PantryFoodItemPriceChangeDialog
                  userInfo={userInfo}
                  order={order}
                  foodItemUpdate={food}
                  orderLog={orderLog}
                  updateOrderLog={updateOrderLog}
                  orderIndex={orderIndex}
                />
              )}

              <p className="Food-Item-Child"> ${individualPrice.toFixed(2)} </p>
            </span>
          </span>
        </div>
      </div>
      {editing && multipleSelection && (
        <Checkbox
          name="foodItem"
          onChange={(e) => handleSelectionChange(e, idx, true)}
        />
      )}
      {editing && !multipleSelection && (
        <EditDistributionLocationItemForm
          foodItem={food}
          order={order}
          foodItemUpdate={food}
          orderLog={orderLog}
          updateOrderLog={updateOrderLog}
          orderIndex={orderIndex}
          userInfo={userInfo}
          limit={food.limit}
          index={idx}
        />
      )}
    </div>
  );
}
