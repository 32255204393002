// Lists all the foodItems that are loaded for the user and that
// come from the farmers that they user has selected to be displayed.
import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

export default function FoodItemSelect({
  displayedFoodItemsToSelect,
  setDistributionQuantity,
  setDescription,
  setIndividualQuantity,
  setIndividualDescription,
  setSuggestedPrice,
  setPrice,
  setCategory,
  setDisplayImage,
  setQuantity,
  setFarmName,
  setItem,
  setLimit,
  setPackageFee,
  setTagList,
  tagList,
  indexOfSelectedItem,
  setIndexOfSelectedItem,
}) {
  // If the user has changed the foodItem.
  const handleSelectionChange = (indexOfFoodItem) => {
    // Set the package fee.
    let packageFee = displayedFoodItemsToSelect[indexOfFoodItem].packageFee;
    // Set the suggestedPrice.
    let suggestedPrice =
      displayedFoodItemsToSelect[indexOfFoodItem].suggestedPrice;
    // Set the limit.
    const limit = displayedFoodItemsToSelect[indexOfFoodItem].limit;
    // If the package fee isn't set then give it a value of 0.
    if (packageFee == null) {
      packageFee = parseFloat(0);
    }
    // If the suggestedPrice isn't set then set it to false.
    if (suggestedPrice == null) {
      suggestedPrice = false;
    }

    // Set the selected foodItem to the selected index the user chose.
    setIndexOfSelectedItem(indexOfFoodItem);

    // Set all the fields of the foodItem.
    setDistributionQuantity(
      displayedFoodItemsToSelect[indexOfFoodItem].distributionQuantity,
    );
    setDescription(displayedFoodItemsToSelect[indexOfFoodItem].description);
    setIndividualQuantity(
      displayedFoodItemsToSelect[indexOfFoodItem].individualQuantity,
    );
    setIndividualDescription(
      displayedFoodItemsToSelect[indexOfFoodItem].individualDescription,
    );
    setSuggestedPrice(suggestedPrice);
    setPrice(displayedFoodItemsToSelect[indexOfFoodItem].price);
    setCategory(displayedFoodItemsToSelect[indexOfFoodItem].category);
    setDisplayImage(displayedFoodItemsToSelect[indexOfFoodItem].displayImage);
    setQuantity(parseInt(1));
    setFarmName(displayedFoodItemsToSelect[indexOfFoodItem].farmName);
    setItem(displayedFoodItemsToSelect[indexOfFoodItem].item);
    // Check if the limit exists if it doesn't then ignore this.
    if (limit != null) {
      setLimit(parseInt(limit));
    }
    setPackageFee(parseFloat(packageFee));
    setTagList(displayedFoodItemsToSelect[indexOfFoodItem]?.tagList || tagList);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel>List of Food Items</InputLabel>
        <Select
          MenuProps={{ disablePortal: true }}
          value={indexOfSelectedItem}
          label="List of Food Items"
          onChange={(e) => handleSelectionChange(e.target.value)}
        >
          {displayedFoodItemsToSelect.map((foodItem, index) => (
            <MenuItem key={index} value={index}>
              {foodItem.item} {foodItem.description} from {foodItem.farmName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
