// This dialog appears and collects information about the foodItem.  It is used
// by all the different places in the site where a user must add to the food item.
// They can enter in a series of different fields though depending on which section
// of the website is making the call the fields may vary.
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SimpleSelect from "./../../userInputs/DropDownMenu.js";
import LoadingContent from "../../LoadingContent.jsx";
import SelectFoodItemToAdd from "./FoodItemInformation/SelectFoodItemToAdd.jsx";
import FoodItemTags from "./FoodItemInformation/FoodItemTags.jsx";

// This is the dialog box that opens when a user clicks the edit button
function FoodItemInformationBox(props) {
  const { onClose, open, foodItem } = props;

  // These are all the options that can be changed for the user.  They can are initialized
  // to the value of the props sent in but are separate as they are what can be modified.
  const [item, setItem] = useState(foodItem.item || "");
  const [distributionQuantity, setDistributionQuantity] = useState(
    foodItem.distributionQuantity || "",
  );
  const [description, setDescription] = useState(foodItem.description || "");
  const [individualQuantity, setIndividualQuantity] = useState(
    foodItem.individualQuantity || "",
  );
  const [individualDescription, setIndividualDescription] = useState(
    foodItem.individualDescription || "",
  );
  const [minimumPurchase, setMinimumPurchase] = useState(
    parseInt(foodItem.minimumPurchase) || 1,
  );
  const [suggestedPrice, setSuggestedPrice] = useState(
    foodItem.suggestedPrice !== undefined ? foodItem.suggestedPrice : false,
  );
  const [price, setPrice] = useState(foodItem.price || "");
  const [category, setCategory] = useState(foodItem.category || "");
  const [displayImage, setDisplayImage] = useState(foodItem.displayImage || "");
  const [quantity, setQuantity] = useState(foodItem.quantity || "");
  const [limit, setLimit] = useState(parseInt(foodItem.limit) || "");
  const [farmLimit, setFarmLimit] = useState(
    parseInt(foodItem.farmLimit) || "",
  );
  const [guaranteedMaxQuantity, setGuaranteedMaxQuantity] = useState(
    foodItem.guaranteedMaxQuantity || "",
  );
  const [farmName, setFarmName] = useState(
    props.farms && props.farms.length > 0
      ? props.farms[0]
      : foodItem.farmName || "",
  );
  const [packageFee, setPackageFee] = useState(
    parseFloat(foodItem.packageFee) || 0,
  );
  const [tagList, setTagList] = useState(foodItem.tagList || []);

  const [selectedDate, setSelectedDate] = useState(new Date());

  let loading = props.loading;

  if (loading == null) {
    loading = true;
  }

  // This useEffect is used when the props change and the form needs to be updated.  This happens
  // if an item is removed, or changed and so there will need to be an update on the form's fields.
  useEffect(() => {
    // Update fields based on new props or set their defaults.
    setItem(foodItem.item || "");
    setDistributionQuantity(foodItem.distributionQuantity || "");
    setDescription(foodItem.description || "");
    setIndividualQuantity(foodItem.individualQuantity || "");
    setIndividualDescription(foodItem.individualDescription || "");
    setMinimumPurchase(parseInt(foodItem.minimumPurchase) || 1);
    setSuggestedPrice(
      foodItem.suggestedPrice !== undefined ? foodItem.suggestedPrice : false,
    );
    setPrice(foodItem.price || "");
    setCategory(foodItem.category || "");
    setDisplayImage(foodItem.displayImage || "");
    setQuantity(parseInt(foodItem.quantity) || "");
    setLimit(parseInt(foodItem.limit) || "");
    setFarmLimit(parseInt(foodItem.farmLimit) || "");
    setGuaranteedMaxQuantity(foodItem.guaranteedMaxQuantity || "");
    setFarmName(
      foodItem.farmName ||
        (props.farms && props.farms.length > 0 ? props.farms[0] : ""),
    );
    setPackageFee(parseFloat(foodItem.packageFee) || 0);
    setTagList(foodItem.tagList || []);
    setSelectedDate(new Date());
  }, [props]);

  // If the user closes or cancels their changes then set everything back to what
  // the original values were sent in through the props.
  const handleClose = () => {
    onClose(false);
    setItem(foodItem.item || "");
    setDistributionQuantity(foodItem.distributionQuantity || "");
    setDescription(foodItem.description || "");
    setIndividualQuantity(foodItem.individualQuantity || "");
    setIndividualDescription(foodItem.individualDescription || "");
    setMinimumPurchase(parseInt(foodItem.minimumPurchase) || 1);
    setSuggestedPrice(
      foodItem.suggestedPrice !== undefined ? foodItem.suggestedPrice : false,
    );
    setPrice(foodItem.price || "");
    setCategory(foodItem.category || "");
    setDisplayImage(foodItem.displayImage || "");
    setQuantity(parseInt(foodItem.quantity) || "");
    setLimit(parseInt(foodItem.limit) || "");
    setFarmLimit(parseInt(foodItem.farmLimit) || "");
    setGuaranteedMaxQuantity(foodItem.guaranteedMaxQuantity || "");
    setFarmName(
      foodItem.farmName ||
        (props.farms && props.farms.length > 0 ? props.farms[0] : ""),
    );
    setPackageFee(parseFloat(foodItem.packageFee) || 0);
    setTagList(foodItem.tagList || []);
    setSelectedDate(new Date());
  };

  // This is when the user saves to the database.
  const handleSave = (e) => {
    e.preventDefault();

    // the state of this dialog is all the fields of the foodItem.
    const state = {
      item,
      distributionQuantity,
      description,
      individualQuantity,
      individualDescription,
      minimumPurchase,
      suggestedPrice,
      price,
      category,
      displayImage,
      quantity,
      limit,
      farmLimit,
      guaranteedMaxQuantity,
      farmName,
      packageFee,
      tagList,
      selectedDate,
    };

    props.callSaveFunction(state, handleClose);
    onClose(false);
  };

  // Checks to make sure the input from the user is valid.  This is mostly used to make sure that
  // a user doesn't use these special characters in the name of the item.
  const validateInput = (name, value) => {
    if (
      (name === "item" ||
        name === "description" ||
        name === "individualDescription") &&
      (value.includes("-") || value.includes(".") || value.includes("/"))
    ) {
      alert(`Please do not use the symbols "-, . or /"`);
      return false; // Return false if validation fails
    }
    return true; // Return true if validation passes
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Fill in information about the item
      </DialogTitle>

      {!loading ? (
        <LoadingContent inline={true} />
      ) : (
        <DialogContent>
          <form onSubmit={handleSave}>
            {props.showAutofill && (
              <SelectFoodItemToAdd
                setItem={setItem}
                setDistributionQuantity={setDistributionQuantity}
                setDescription={setDescription}
                setIndividualQuantity={setIndividualQuantity}
                setIndividualDescription={setIndividualDescription}
                setSuggestedPrice={setSuggestedPrice}
                setPrice={setPrice}
                setCategory={setCategory}
                setDisplayImage={setDisplayImage}
                setQuantity={setQuantity}
                setLimit={setLimit}
                setFarmName={setFarmName}
                setPackageFee={setPackageFee}
                setTagList={setTagList}
                tagList={tagList}
              />
            )}
            <span>
              <TextField
                margin="dense"
                id="item"
                label="Item"
                name="item"
                value={item}
                onChange={(e) => {
                  if (validateInput(e.target.name, e.target.value)) {
                    setItem(e.target.value);
                  }
                }}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="distributionQuantity"
                label="Distribution Quantity"
                name="distributionQuantity"
                type="number"
                value={distributionQuantity}
                onChange={(e) => setDistributionQuantity(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="description"
                label="Distribution Description"
                name="description"
                value={description}
                onChange={(e) => {
                  if (validateInput(e.target.name, e.target.value)) {
                    setDescription(e.target.value);
                  }
                }}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="individualQuantity"
                label="Individual Quantity"
                name="individualQuantity"
                type="number"
                value={individualQuantity}
                onChange={(e) => setIndividualQuantity(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="individualDescription"
                label="Individual Description"
                name="individualDescription"
                value={individualDescription}
                onChange={(e) => {
                  if (validateInput(e.target.name, e.target.value)) {
                    setIndividualDescription(e.target.value);
                  }
                }}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="minimumPurchase"
                label="Minimum Purchase Quantity"
                name="minimumPurchase"
                type="number"
                value={minimumPurchase}
                onChange={(e) => setMinimumPurchase(parseInt(e.target.value))}
                fullWidth
              />
              <FormControlLabel
                label="Suggested Price"
                control={
                  <Switch
                    checked={suggestedPrice}
                    name="suggestedPrice"
                    onChange={() => setSuggestedPrice(!suggestedPrice)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
              <TextField
                margin="dense"
                id="price"
                label="Price ($CAD)"
                name="price"
                value={price}
                type="number" // Ensure the input is of type number
                onChange={(e) => setPrice(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="category"
                label="Category"
                name="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="displayImage"
                label="Display Image"
                name="displayImage"
                value={displayImage}
                onChange={(e) => setDisplayImage(e.target.value)}
                fullWidth
              />
              {props.userInfo.userType === "distributionLocation" && (
                <span>
                  <TextField
                    margin="dense"
                    id="quantity"
                    label="Quantity"
                    name="quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                    fullWidth
                    required
                  />
                  {props.addToDistributionLocation !== true && (
                    <TextField
                      margin="dense"
                      id="limit"
                      label="Remaining in Pantry"
                      name="limit"
                      value={limit}
                      type="number"
                      onChange={(e) => setLimit(parseInt(e.target.value))}
                      fullWidth
                      required={props.limitRequired} // Conditionally set the required attribute
                    />
                  )}
                </span>
              )}
              {props.userInfo.userType === "admin" && (
                <TextField
                  margin="dense"
                  id="farmLimit"
                  label="Farm Limit"
                  name="farmLimit"
                  value={farmLimit}
                  onChange={(e) => setFarmLimit(parseInt(e.target.value))}
                  type="number"
                  fullWidth
                />
              )}

              {farmLimit > 0 && (
                <TextField
                  margin="dense"
                  id="guaranteedMaxQuantity"
                  label="Guaranteed Max Quantity"
                  name="guaranteedMaxQuantity"
                  value={guaranteedMaxQuantity}
                  onChange={(e) => setGuaranteedMaxQuantity(e.target.value)}
                  type="number"
                  fullWidth
                />
              )}
              {props.farms !== undefined ? (
                <SimpleSelect
                  margin="dense"
                  id="farmName"
                  title="Supplying Farm"
                  options={props.farms}
                  handleChange={(e) => setFarmName(e.target.value)}
                  selected={farmName}
                  required
                />
              ) : (
                <TextField
                  margin="dense"
                  id="farmName"
                  name="farmName"
                  label="Supplying Farm"
                  value={farmName}
                  onChange={(e) => setFarmName(e.target.value)}
                  fullWidth
                  required
                />
              )}
              <TextField
                margin="dense"
                id="packageFee"
                label="Package Fee"
                name="packageFee"
                value={packageFee}
                onChange={(e) => setPackageFee(parseFloat(e.target.value))}
                type="number"
                fullWidth
                required
              />
              <FoodItemTags tagList={tagList} setTagList={setTagList} />
              {props.addToDistributionLocation && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}
            </span>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
}

FoodItemInformationBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function FoodItemInformationDialog(props) {
  const [open, setOpen] = useState(false);

  let foodItem = { ...props.foodItem };

  if (foodItem == null) {
    foodItem = {};
  }

  const handleClickOpen = () => {
    setOpen(true);
    if (props.setParentOpen) {
      props.setParentOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  let dialogButton = (
    <IconButton onClick={handleClickOpen}>
      <EditIcon />
    </IconButton>
  );

  if (props.buttonStyle === 1) {
    dialogButton = (
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        ADD ITEM
      </Button>
    );
  } else if (props.buttonStyle === 2) {
    dialogButton = (
      <Button
        style={{
          backgroundColor: "rgb(46, 125, 50)",
          color: "white",
          marginBottom: "10px",
          width: "100%",
        }}
        variant="contained"
        onClick={handleClickOpen}
      >
        CREATE A NEW ITEM
      </Button>
    );
  }

  return (
    <div>
      {dialogButton}
      <FoodItemInformationBox
        open={open}
        onClose={handleClose}
        foodItem={foodItem}
        {...props}
      />
    </div>
  );
}
