// This class is used to update the a marketplace item from the admin's marketplace.
import React from "react";
import firebase from "./../Firebase.js";
import ModifyCategoryAtDistributionLocation from "../../functions/ModifyCategoryAtDistributionLocation.js";
import HandleRefresh from "./../../pages/MarketplacePage/Functions/HandleRefresh.js";
import CreateFarmCategories from "../../functions/CreateFarmCategories.js";
import FoodItemInformationDialog from "./../dialogs/Components/FoodItemInformationDialog.jsx";

export default function MarketplaceEditForm(props) {
  const handleSave = (state, handleClose) => {
    // This is the original foodItem that was the user is editing and updating.
    const oldFoodItem = props.foodItem;
    const database = firebase.firestore();
    const batch = database.batch();
    const item = state.item;
    const description = state.description;
    const category = state.category;
    const farmLimit = state.farmLimit;
    const guaranteedMaxQuantity = state.guaranteedMaxQuantity;
    const farmName = state.farmName;

    const documentName = (
      state.item +
      state.description +
      state.farmName
    ).replace(/\s+/g, "");

    // Updates the foodItem document.
    const docRef = database.collection("FoodItems").doc(documentName);

    const newItem = { ...state };
    newItem.userId = firebase.auth().currentUser.uid;
    newItem.adminVisible = false;
    newItem.distributionLocationVisible = [];
    newItem.userType = props.userInfo.userType;

    // Checks to see if farmLimit exists.  If it does then add it to the item
    // otherwise this item won't have a farmLimit.
    if (farmLimit) {
      newItem.farmLimit = parseFloat(farmLimit);
    }
    // Check if the guaranteedMax Quantity field exists if it does then
    // we want to add it to the item.
    if (guaranteedMaxQuantity > 0 && farmLimit > 0) {
      newItem.guaranteedMaxQuantity = parseInt(guaranteedMaxQuantity);
    }

    batch.set(docRef, newItem);
    batch.commit();

    // Checks to see if the name, description or farmName have changed.  Since we
    // save items in the database using these properties we'll have to delete the item
    // and make a new one.
    if (
      item !== oldFoodItem.item ||
      description !== oldFoodItem.description ||
      farmName !== oldFoodItem.farmName
    ) {
      const deleteDocumentName = (
        oldFoodItem.item +
        oldFoodItem.description +
        oldFoodItem.farmName
      ).replace(/\s+/g, "");

      database
        .collection("FoodItems")
        .doc(deleteDocumentName)
        .delete()
        .then(function () {})
        .catch(function (error) {
          console.error("Error removing document: ", error);
        });
    }

    // Finds the index of the item to allow for HandleRefresh to modify the correct
    // foodItem.  It uses the props instead of the state in case the user changed
    // those values and it can still be found.
    const index = props.foodItems.findIndex((foodItem) => {
      return (
        foodItem.description === oldFoodItem.description &&
        foodItem.farmName === oldFoodItem.farmName &&
        foodItem.item === oldFoodItem.item
      );
    });

    // HandleRefresh returns an updated foodItems list.
    const foodItemsNew = HandleRefresh(
      props.foodItems,
      props.setFoodItems,
      newItem,
      false,
      true,
      index,
    );

    // Checks if the user changed the category or the farm because either could
    // trigger a farmer category array change.
    if (
      category !== oldFoodItem.category ||
      farmName !== oldFoodItem.farmName
    ) {
      // sends in the newFoodItems list creates the new farm categories.
      const newFarmCategories = CreateFarmCategories(foodItemsNew);

      // Sends in the updated farmCategories, the farm new farm and the old farm.
      // If the user changed the category and not the farm then both farms will
      // be the same.
      ModifyCategoryAtDistributionLocation(
        newFarmCategories,
        farmName,
        oldFoodItem.farmName,
      );
    }
    handleClose();
    console.log("Successfully wrote to database!");
  };

  return (
    <div>
      <FoodItemInformationDialog
        buttonStyle={0}
        callSaveFunction={handleSave}
        {...props}
      />
    </div>
  );
}
