// This is to add an item the the pantry of a distribution location

import React, { useState, useEffect } from "react";
import AddItemToPantry from "./../../Pantry/Functions/AddItemToPantry.js";
import FoodItemInformationDialog from "./../../dialogs/Components/FoodItemInformationDialog.jsx";
import LoadMarketplaceItems from "./../../../functions/LoadMarketplaceItems.js";

export const SetAddToPantryVariablesContext = React.createContext();

export default function AddItemToPantryDialog(props) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  // The list of all the food Items available.
  const [foodItemsToSelect, setFoodItemsToSelect] = useState([]);
  // The foodItems that are being shown in case the user has selected
  // a farm to be removed.
  const [displayedFoodItemsToSelect, setDisplayedFoodItemsToSelect] = useState(
    [],
  );
  // The farmers that exist and provide the foodItems.
  const [farmersAvailable, setFarmersAvailable] = useState([]);

  // Load the foodItems that are available for the user to select
  // from to add if they would like to autofill.
  useEffect(() => {
    // If the length is 0 then we haven't loaded the foodItems yet.
    // We want it to be open so that we don't end up with a bunch
    // of loads when we only need to do it once when the user opens.
    if (foodItemsToSelect.length === 0 && open) {
      // Load foodItemsToSelect
      LoadMarketplaceItems(
        null,
        1000,
        true,
        {},
        setFoodItemsToSelect,
        setDisplayedFoodItemsToSelect,
        null,
        setLoading,
        props.userInfo,
        {},
      );
    }
    // If the length is greater than 0 then we have already loaded
    // the foodItems and can just set loading to true.
    else if (foodItemsToSelect.length > 0 && open) {
      setLoading(true);
    }
  }, [open]);

  // Once we have the list of foodItems then we have to set the list
  // of farmers available so the user can toggle the items from each farm
  // on or off.
  useEffect(() => {
    //  Make sure the foodItems exist and that the user has opened
    // the dialog.
    if (foodItemsToSelect.length > 0 && open) {
      const farmersAvailableTemp = [];
      // Cycle through all the foodItems and add the farms
      // to the list.  Some items will come from the same
      // farm so check if it's already been added.
      foodItemsToSelect.forEach((foodItem) => {
        if (!farmersAvailableTemp.includes(foodItem.farmName)) {
          farmersAvailableTemp.push(foodItem.farmName);
        }
      });
      farmersAvailableTemp.sort();
      setFarmersAvailable([...farmersAvailableTemp]);
    }
  }, [foodItemsToSelect, open]);

  const handleSave = (state, handleClose) => {
    // This commits to the pantry in the database and updates the page.  Leftovers
    // is the current list of leftovers and setLeftovers updates the page's hook.
    // The userInfo and state send the information needed to update the datavase.
    AddItemToPantry(props.leftovers, props.setLeftovers, props.userInfo, state);

    handleClose();
  };

  return (
    <SetAddToPantryVariablesContext.Provider
      value={{
        displayedFoodItemsToSelect,
        farmersAvailable,
        foodItemsToSelect,
        setDisplayedFoodItemsToSelect,
        open,
      }}
    >
      <FoodItemInformationDialog
        buttonStyle={1}
        callSaveFunction={handleSave}
        userInfo={props.userInfo}
        showAutofill={true}
        loading={loading}
        setParentOpen={setOpen}
        limitRequired={true}
      />
    </SetAddToPantryVariablesContext.Provider>
  );
}
