// Need to send in the Community Hub Chunks from the useContext
// communityOrderChunks are in {orderdate: [communityOrders, overFlowCommunityOrders1, etc.]}
// This is how we have to save any changes to the communityOrders in order to make sure that
// the chunks are respected otherwise we may hit caps on the order document.
import CreateOverFlowOrderDocument from "./../CreateOverFlowOrderDocument.js";
import firebase from "../../../../components/Firebase.js";

export default function UpdateCommunityOrdersDatabase(
  typeOfUpdate,
  batch,
  communityOrderChunks,
  setCommunityOrderChunks,
  communityOrdersTemp,
  orderDate,
  user,
  userOrder,
) {
  const database = firebase.firestore();
  // This is the orderChunks that exist already.  They are saved
  // with the orderDate as the key and then each order date has an
  // array with each order chunk.
  const communityOrderChunksTemp = { ...communityOrderChunks };

  // Updates the distribution location's order history
  let docRef = database
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("Orders")
    .doc(orderDate);

  // If the update is only updating the userOrder then we just need to find it and
  // update it.
  if (typeOfUpdate === "SingleUserOverwrite") {
    // the index of the chunk. 0 is the main order document where as 1+ is all the
    // overflow docs.
    let indexOfChunkWithUser = 0;
    // This is the array of chunks for this orderDate.
    const communityOrderChunksCurrentDate = communityOrderChunks[orderDate];
    // Update a key/value pair within communiy
    // Find the user in the chunks and update the right chunk
    for (let i = 0; i < communityOrderChunksCurrentDate.length; i++) {
      // List of all the user's orders.
      const arrayOfKeys = Object.keys(communityOrderChunksCurrentDate[i]);
      // Checks to see if this user order which is firstName-lastName-userId-orderDate matches
      if (arrayOfKeys.includes(user)) {
        indexOfChunkWithUser = i;
        break;
      }
    }
    // If the index is greater than 0 we know it was in an overFlow doc.
    if (indexOfChunkWithUser > 0) {
      const overFlowDocumentName = orderDate.concat(indexOfChunkWithUser);

      docRef = database
        .collection("Users")
        .doc(firebase.auth().currentUser.uid)
        .collection("Orders")
        .doc(orderDate)
        .collection("OverFlow")
        .doc(overFlowDocumentName);
      // Update the user's order in the order chunk.
      communityOrderChunksTemp[orderDate][indexOfChunkWithUser][user] = {
        ...userOrder,
      };
      // Update the orderChunks hook.
      setCommunityOrderChunks({ ...communityOrderChunksTemp });
    }

    // The order comes from the communityOrders this is used to set the
    // database name.  Add the userkey and the date to find the correct key to modify the order
    const userDataRef = `communityOrders.${user}`;

    batch.update(docRef, {
      [userDataRef]: { ...userOrder },
    });
  }
  // If you're not updating just the user's order then we have to update all the communityOrders to make
  // sure that there are still the correct amount of orders in each chunk.
  else {
    // If there is an order being removed then we delete the order from the list of all communityOrders.
    if (typeOfUpdate === "RemovalOfOrder") {
      delete communityOrdersTemp[user];
    }
    // Redo the communityOrders and put them into new chunks.
    const [overFlowCount, communityOrdersFirstChuck, newCommunityOrderChunks] =
      CreateOverFlowOrderDocument(communityOrdersTemp, orderDate, batch);
    // Update the database and replace the communityOrders section of the order doc and the overFlowCount.
    batch.update(docRef, {
      communityOrders: { ...communityOrdersFirstChuck },
      overFlowCount,
    });
    // Update the orderChunk's hook.
    communityOrderChunksTemp[orderDate] = [...newCommunityOrderChunks];
    setCommunityOrderChunks({ ...communityOrderChunksTemp });
  }
}
